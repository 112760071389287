import React, {
  createContext,
  useContext,
  useRef,
} from "react";
import NotificationSystem from "react-notification-system";

export const NotificationContext = createContext({});

export const NotificationProvider = ({ children }) => {
  const notificationSystem = useRef();

  const addNotification = ({ title, message, level }) => {
    const notification = notificationSystem.current;

    notification.addNotification({
      title,
      message,
      level,
    });
  };

  return (
    <NotificationContext.Provider value={{ addNotification }}>
      <NotificationSystem ref={notificationSystem} />
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => useContext(NotificationContext);
