import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import logo from "./logo1.png";
export default function Nav() {
  const [navOpen, setNavOpen] = useState(false);

  return (
    <nav className="drop-shadow-sm fixed w-full bg-white z-50 mb-12">
      <div className="bg-white pt-4 pr-8 pb-4 pl-8">
        <div className="w-full">
          <div className="flex w-full mt-auto mr-auto mb-auto ml-auto justify-between max-w-screen-2xl md:flex-row">
            <div className="flex flex-row  items-center">
              <NavLink to="/">
                <img
                  className="btn- object-cover w-40"
                  src={logo}
                  alt="user "
                />
                {/* <p className=" text-xl justify-self-start font-bold text-blue-800 tracking-wide uppercase">
                  Digiprovest
                </p> */}
              </NavLink>
            </div>
            <div className="flex-row bg-white self-center justify-between mt-2 mb-2 md:m-0 hidden md:flex">
              <NavLink
                to="/about"
                className="text-gray-600 text-center mx-4 font-medium text-base "
              >
                About
              </NavLink>
              <NavLink
                to="/staking"
                className="text-gray-600 text-center mx-4 font-medium text-base "
              >
                Staking
              </NavLink>
              <NavLink
                to="/packages"
                className="text-gray-600 text-center mx-4 font-medium text-base "
              >
                Earn
              </NavLink>
              <NavLink
                to="/otc"
                className="text-gray-600 text-center mx-4 font-medium text-base "
              >
                OTC
              </NavLink>
              <NavLink
                to="/referral"
                className="text-gray-600 text-center mx-4 font-medium text-base"
              >
                Referral
              </NavLink>
              <NavLink
                to="/faq"
                className="text-gray-600 text-center mx-4 font-medium text-base"
              >
                FAQ
              </NavLink>
            </div>

            <div className=" flex-row items-center justify-center md:m-0 hidden md:flex">
              <NavLink
                to="/login"
                className="h-9 w-24 text-gray-600 bg-white border-2 border-white flex items-center justify-center
                text-center rounded-lg text-lg font-normal mr-2"
              >
                Login
              </NavLink>
              <NavLink
                to="/sign-up"
                className="h-9 w-24 text-white bg-blue-500 hover:bg-blue-700  flex
                items-center justify-center text-center rounded-lg  font-normal"
              >
                Sign up
              </NavLink>
            </div>

            <div
              onClick={() => setNavOpen(!navOpen)}
              className="md:hidden flex ml-auto items-center"
            >
              <div className="outline-none mobile-menu-button cursor-pointer">
                <svg
                  fill="none"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="w-6 h-6 text-gray-500
                  hover:text-green-500"
                >
                  <path d="M4 6h16M4 12h16M4 18h16" />
                </svg>
              </div>
            </div>
          </div>
          <div
            className={`pt-12 transition duration-2 ease-1 transform md:hidden mobile-menu ${
              navOpen ? "flex flex-col items-center" : "hidden"
            }`}
          >
            <NavLink
              to="/about"
              className="text-gray-600 mb-4 text-center font-medium text-base "
            >
              About
            </NavLink>
            <NavLink
              to="/staking"
              className="text-gray-600 mb-4 text-center font-medium text-base "
            >
              Staking
            </NavLink>
            <NavLink
              to="/packages"
              className="text-gray-600 mb-4 text-center font-medium text-base "
            >
              Earn
            </NavLink>
            <NavLink
              to="/otc"
              className="text-gray-600 mb-4 text-center font-medium text-base "
            >
              OTC
            </NavLink>
            <NavLink
              to="/referral"
              className="text-gray-600 mb-4 text-center font-medium text-base "
            >
              Referral
            </NavLink>
            <NavLink
              to="/faq"
              className="text-gray-600 mb-4 text-center font-medium text-base "
            >
              FAQ
            </NavLink>

            <NavLink
              to="/login"
              className="h-9 w-24 bg-white hover:bg-blue-900 hover:text-white text-blue-700 hover:border-blue-900 border flex
                  items-center justify-center text-center border-blue-700 rounded-lg text-lg font-normal mb-4 mr-auto
                  ml-auto"
            >
              Login
            </NavLink>
            <NavLink
              to="/sign-up"
              className="h-9 w-24 text-white bg-blue-700 hover:bg-blue-900 hover:border-blue-900 border-2 flex
                  items-center justify-center text-center border-blue-700 rounded-lg text-lg font-normal mr-auto
                  ml-auto"
            >
              Sign up
            </NavLink>
          </div>
        </div>
      </div>
    </nav>
  );
}
