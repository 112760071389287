import React, { createContext, useContext, useState } from "react";
export const DashboardSectionContext = createContext({});

export const DashboardSectionProvider = ({ children }) => {
  const [currentSection, setCurrentSection] = useState("balance");

  return (
    <DashboardSectionContext.Provider
      value={{ currentSection, setCurrentSection }}
    >
      {children}
    </DashboardSectionContext.Provider>
  );
};

export const useDashboardSection = () => useContext(DashboardSectionContext);
