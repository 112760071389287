import InvestmentPlan from "../../components/InvestmentPlan";
import Layout from "../../components/Layout";
import { investmentPlans } from "../../utils/investmentPlans";
export default function Packages() {
  return (
    <Layout>
      <main
        className="page-content flex flex-col items-center"
        aria-label="Content"
      >
        <div className="bg-black pt-16 text-white w-full">
          <div className="bg-transparent relative mr-auto ml-auto">
            <div
              className="bg-transparent pt-10 pr-4 pb-10 pl-4 mr-auto ml-auto sm:max-w-xl md:max-w-full lg:px-8 md:px-24
            lg:max-w-screen-xl "
            >
              <div className="flex flex-col items-center justify-between w-full mb-10 lg:flex-row">
                <div className="mb-16 lg:mb-0 lg:max-w-lg lg:pr-5">
                  <div className="mb-6 max-w-xl">
                    <div className=" font-bold leading-tight text-white   mb-6">
                      <p className="text-3xl md:text-5xl font-bold mb-6 sm:text-4xl sm:leading-none">
                        Supercharge your crypto investments
                      </p>
                    </div>
                    <p className="bg-transparent text-base mb-6 md:text-lg">
                      Choose an investment plan and we will make use of complex
                      trading and risk management techniques to improve
                      investment performance and insulate returns from market
                      risk.
                    </p>
                    <a
                      href="/dashboard"
                      className="border-0 rounded-lg mt-4 py-3 px-8 text-white bg-blue-500  font-bold text-center"
                    >
                      Start Investing
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="pt-20 pr-0 pb-20 pl-0 relative">
            <div className=" text-black mt-0 mr-auto mb-0 ml-auto pt-0 pr-10 pb-0 pl-10 relative max-w-7xl lg:px-16">
              <div className="mt-0 mr-auto mb-16 ml-auto text-center max-w-3xl lg:mb-24">
                <p className="mt-3 mr-0 mb-5 ml-0 text-4xl font-medium font-heading">
                  Choose a plan and start earning
                </p>
                <p className="mt-0 mr-0 mb-3 ml-0 text-xl text-gray-400">
                  Unlock the potential of digital currency investing
                </p>
              </div>
              <div className="md:grid md:grid-cols-2 lg:grid-cols-3 gap-10">
                {investmentPlans.map((plan) => (
                  <InvestmentPlan key={plan.title} plan={plan} />
                ))}
              </div>
            </div>
          </div>
          <section className="pt-8 pb-8 border-bottom  text-md-left">
            <p className="mt-3 mr-0 mb-5 ml-0 text-4xl text-center font-medium font-heading">
              Why choose us
            </p>
            <div className="flex flex-col gap-4 my-16 mx-4 lg:mx-16">
              <div className="border border-blue-500 p-4 py-8">
                <h3 className="mb-4 text-blue-500">
                  SOCIALLY RESPONSIBLE INVESTING
                </h3>
                <p>
                  Based on our Portfolio Strategy, we increase weight in tokens
                  of projects meeting certain social, environmental, and
                  governance criteria. This helps express investment preference
                  for social impact and also maintains diversification and low
                  costs.
                </p>
              </div>
              <div className="border border-blue-500 p-4 py-8">
                <h3 className="mb-4 text-blue-500">
                  ALLOCATE ASSETS FOR OPTIMAL PERFORMANCE
                </h3>
                <p>
                  If your investment preferences diﬀer from our recommendations,
                  you can adjust individual asset class weights in your digiprovest
                  portfolio to express them. How your money is spread across
                  cryptocurrencies can impact your long-term returns. We’ll
                  recommend how much risk to take on based on when you’ll need
                  your money.
                </p>
              </div>
              <div className="border border-blue-500 p-4 py-8">
                <h3 className="mb-4 text-blue-500">DIVERSIFIED PORTFOLIO</h3>
                <p>
                  Your portfolio can benefit from growth of both developed and
                  emerging projects, so you’re less exposed to any particular
                  niche. Diversified portfolio strategy that uses certain
                  factors that attempt to outperform a conventional market-cap
                  strategy.
                </p>
              </div>
            </div>
          </section>
          <section className="pt-8 pb-8 border-bottom  text-md-left">
            <p className="mt-3 mr-0 mb-2  ml-0 text-4xl text-center font-medium font-heading">
              How we earn
            </p>
            <p className="mb-5 text-center">
              Some ways we do the hardwork for you
            </p>
            <div className="flex flex-col gap-4 my-16 mx-4 lg:mx-16">
              <div className="p-4 border-x-2 border-x-blue-500 bordr-y shadow">
                <h3 className="font-normal text-lg mb-5 text-blue-500">
                  Investing in Promising New Coins Early{" "}
                </h3>
                <p className="text-sm leading-relaxed">
                  The overall best way to make money with cryptocurrency is to
                  invest in the best altcoins as early as possible. After all,
                  had invested in Bitcoin when the digital currency was first
                  launched in 2009, you would have paid a tiny fraction of one
                  cent. Similarly, Ethereum was trading at just $0.75 per token
                  when its token was released in 2015. Both of the
                  aforementioned projects are now worth millions of dollars and
                  have subsequently generated significant returns for our
                  investors at Digiprovest.
                </p>
              </div>
              <div className="p-4 border-x-2 border-x-blue-500 bordr-y shadow">
                <h3 className="font-normal text-lg mb-5 text-blue-500">
                  Staking and Interest{" "}
                </h3>
                <p className="text-sm leading-relaxed">
                  At Digiprovest we earn Passive Income on Idle Cryptocurrency
                  Tokens for our investors. There are two notable concepts in
                  the cryptocurrency markets that enable you to earn passive
                  income on idle digital tokens that you own. The first is
                  crypto staking, which involves locking your tokens away for a
                  certain amount of time to help validate transactions on
                  proof-of-stake blockchain networks. Examples of leading
                  staking networks include Ethereum, Solana, and Cardano.
                  Crucially, you will receive a rate of interest for as long as
                  your tokens are locked away for a long period of time.
                </p>
              </div>
              <div className="p-4 border-x-2 border-x-blue-500 bordr-y shadow">
                <h3 className="font-normal text-lg mb-5 text-blue-500">
                  Day Trading{" "}
                </h3>
                <p className="text-sm leading-relaxed">
                  One of the most lucrative ways we make money for our investors
                  at Digiprovset is to actively engage in day trading. However
                  to achieve this we have a basic understanding of how to
                  analyze prices to determine whether the token in question is
                  likely to rise or fall in value, and this analysis carefully
                  conducted by our expert analysts in the emerging technologies
                  market, and with the aid of our bots we are able to achieve
                  massive success day trading cryptocurrencies.
                </p>
              </div>
              <div className="p-4 border-x-2 border-x-blue-500 bordr-y shadow">
                <h3 className="font-normal text-lg mb-5 text-blue-500">DAOs</h3>
                <p className="text-sm leading-relaxed">
                  We buy a Share in a Decentralized Autonomous Organization. In
                  addition to the metaverse and NFTs, decentralized autonomous
                  organizations (DAOs) are expected to play a significant role
                  in the future of cryptocurrency and blockchain technology.
                  DAOs refer to projects that are collectively owned by the
                  community and investors. And, in order to become a part-owner
                  of a DAO, you simply need to hold the respective token. We
                  have followed many DAO's from inception and holld tokens for a
                  good number of them. One such example is
                  Uniswap. This project is home to a decentralized exchange that
                  enables people to buy, sell, and trade digital currencies
                  without the presence of a centralized third party. Uniswap has
                  since launched its DAO cryptocurrency and thus – the project
                  is owned by token holders like Digiprovest. And, this means that any
                  profits generated by the Uniswap exchange are subsequently
                  distributed to those holding its DAO token on a proportionate
                  basis. Moreover, those holding a DAO token have a say in how
                  the respective project is run. This means that in order for a
                  DAO project to make a decision about future development, it
                  must first go to a vote.
                </p>
              </div>
              <div className="p-4 border-x-2 border-x-blue-500 bordr-y shadow">
                <h3 className="font-normal text-lg mb-5 text-blue-500">
                  Yield Farming and Lending
                </h3>
                <p className="text-sm leading-relaxed">
                  Although both of these investment concepts allow you to earn
                  interest passively, they actually refer to slightly different
                  methods. First and foremost, crypto yield farming refers to
                  the process of lending your idle tokens to a liquidity pool.
                  In the vast majority of cases, you will be providing
                  much-needed liquidity to decentralized exchanges. Some of the
                  leaders in this market include Pancakeswap and Uniswap on the
                  Binance and Ethereum blockchain networks, respectively. When
                  you deposit funds into a liquidity pool, it is often locked
                  away for a minimum amount of time. And, for as long as the
                  tokens are in the liquidity pool, you will be paid a rate of
                  interest. In many cases, the newer and less liquid that a
                  cryptocurrency is, the higher the respective APY offered by
                  the pool. When it comes to crypto lending, this refers to the
                  same concept that we discussed earlier – insofar that you will
                  deposit your digital tokens into a savings account. And in
                  doing so, your tokens will be lent to those that wish to
                  borrow funds. When engaging in crypto lending, it is important
                  that you choose your preferred platform wisely. Once again,
                  this is why Digiprovest stands out as the platform only lends
                  crypto to high-grade borrowers that have been pre-vetted.
                </p>
              </div>
            </div>
          </section>
        </div>
        <div className="py-20 px-8 bg-blue-500 text-white text-center">
          <p className="text-4xl mb-4 font-bold">
            Be In Charge Of Your Crypto{" "}
          </p>

          <p className="text-2xl mb-12">
            Digiprovest is an ultimate solution for modern crypto investment. You
            can do it all from the comfort of one single app. Earn with staking,
            Purchase, Instantly Exchange at best rates, get crypto loans, add
            extra security layer with Multisignature, and the list goes on.
          </p>

          <a
            className="py-4 px-8 bg-white text-blue-500 rounded"
            href="/signup"
          >
            Get Started
          </a>
        </div>
      </main>
    </Layout>
  );
}
