import React from "react";
import { Tab } from "@headlessui/react";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function TransactionsTab({ title }) {
  return (
    <Tab
      className={({ selected }) =>
        classNames(
          "w-full rounded-lg py-2.5 text-sm font-medium leading-5 ",
          "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-0",
          selected
            ? "bg-white shadow text-blue-700"
            : "hover:bg-white/[0.12] text-white hover:text-white"
        )
      }
    >
      {title}
    </Tab>
  );
}
