import Layout from "../../components/Layout";


export default function CookiesPolicy() {
  return (
    <Layout>
      <main >
        <div className="s-article s-article--legal-lists mx-auto max-w-2xl">
          <p></p>
          <h1 className="text-2xl my-8">Cookies Policy</h1>
          <h2 className="text-xl my-10 mx-4">I. Introduction</h2>
          <p>
            This Cookies Policy (the <strong>“Policy”</strong>) governs the
            relations between you (<strong>“Client”</strong> or{" "}
            <strong>“you”</strong>) and any holding company, subsidiary or
            entity belonging to the Digiprovest group of companies (
            <strong>“Paxcrypt”</strong>, <strong>“we”</strong> or{" "}
            <strong>“us”</strong>), while you and Digiprovest are hereinafter
            separately referred to as <strong>“Party”</strong> and jointly - as{" "}
            <strong>“Parties”</strong>, in regard to your use of the Digiprovest
            Services and the use and application of cookies and similar
            technologies while using the Digiprovest Platform.&nbsp;
          </p>
          <p>
            You shall take your time and carefully read this Policy before using
            the Digiprovest Services, so you are fully aware of your rights and
            obligations. In the spirit of transparency, this Policy provides
            detailed information about how and when we use cookies on the Digiprovest
            Platform.&nbsp;
          </p>
          <p>
            Unless stated otherwise, references shall be made to the Digiprovest
            Wallet Services General Terms and Conditions, Digiprovest Crypto Credit
            General Terms and Conditions, Digiprovest Earn Interest Product General
            Terms and Conditions, Digiprovest Exchange Service General Terms and
            Conditions and the Privacy Policy (jointly the “Paxcrypt General
            Terms”), and all the defined terms, used in this Policy, shall have
            the same meaning as the one given to them in the Digiprovest General
            Terms, as the case may be.
          </p>
          <p>
            For the purposes of carrying out smooth electronic communication
            process and technically error-free and optimized provision of the
            Digiprovest Services, we store cookies on the grounds of your
            consent, for our legitimate interests and legitimate interests of
            third parties, as well as on other legal grounds under the relevant
            local legislation in every country of our operations. For the
            avoidance of doubt, this Policy does not apply to any digital
            services targeted at children.
          </p>
          <h2 className="text-xl my-10 mx-4">II. Do We Use Cookies?</h2>
          <p>
            Digiprovest and its partners, affiliates, analytics and service providers
            use cookies and other similar technologies to ensure that everyone
            who uses the Digiprovest Platform has the best possible user experience.
            When you visit the Digiprovest Platform for the first time, a cookie
            banner will pop up and will give you an explanation about the
            cookies we use. As soon as you click on the “Accept All” button, you
            consent to us using all the categories of cookies, as described in
            this Policy. You can disable the use of cookies via your browser.
          </p>
          <h2 className="text-xl my-10 mx-4">III. What Is a Cookie?</h2>
          <p>
            A cookie is a small text file containing information generated by a
            website&apos;s server and placed on your personal computer or smart
            device. Cookies enable the website and your browser to interact in
            such a way that the website is able to present you with the most
            appropriate options and to customize services to your interests,
            based on your previous use of the website. Some of the cookies will
            only be used if you use certain features of our services or select
            certain preferences, and some cookies will always be used. You can
            find out more about each cookie we use by viewing our current
            Cookies List below and/or manage your preferences via the Manage
            Cookies button. We update this list regularly, so there may be
            additional cookies that are not yet listed.
          </p>
          <h2 className="text-xl my-10 mx-4">IV. Cookies List</h2>
          <p>
            <strong>1.</strong> Depending on the purpose of each cookie, the
            cookies we use are:&nbsp;&nbsp;
          </p>
          <p>
            <strong>1.1. Strictly Necessary Cookies</strong> - they are
            necessary for the Digiprovest Platform to function properly and cannot be
            switched off in our system. They are usually only set in response to
            actions made by you which amount to a request for services, such as
            setting your privacy preferences, logging in or filling in forms.
            You can set your browser to block or alert you about these cookies,
            but some parts of the Digiprovest Platform will not then work properly.
            These cookies do not store any personally identifiable information.
          </p>
          <p>
            <strong>1.2. Functional Cookies</strong> - they enable the Digiprovest
            Platform to provide enhanced functionality and personalisation. They
            may be set by us or by third-party providers whose services we have
            added to the Digiprovest Platform. If you do not allow these cookies, then
            some or all of these services may not function properly.
          </p>
          <p>
            <strong>1.3. Performance Cookies</strong> - they allow us to count
            visits and traffic sources so we can measure and improve the
            performance of the Digiprovest Platform. They help us know which pages are
            the most and least popular and see how visitors move around the
            Digiprovest Platform. All information these cookies collect is aggregated
            and therefore anonymous. If you do not allow these cookies, we will
            not know when you have visited the Digiprovest Platform, and will not be
            able to monitor its performance.
          </p>
          <p>
            <strong>1.4. Targeting Cookies </strong>- These cookies may be set
            through the Digiprovest Platform by our advertising partners. They may be
            used by those companies to build a profile of your interests and
            show you relevant adverts on other sites. They do not store directly
            personal information, but are based on uniquely identifying your
            browser and internet device. If you do not allow these cookies, you
            will experience less targeted advertising.
          </p>
          <p>
            <strong>1.5. Social Media Cookies </strong>- These cookies are set
            by a range of social media services that we have added to the Digiprovest
            Platform to enable you to share our content with your friends and
            networks. They are capable of tracking your browser across other
            sites and building up a profile of your interests. This may impact
            the content and messages you see on other websites you visit. If you
            do not allow these cookies you may not be able to use or see these
            sharing tools.
          </p>
          <p>
            <strong>1.6. Unknown Cookies</strong> - These cookies are used to
            better your customer journey on our site as well as collect
            information about your preferences and actions on the site. They do
            not collect personally identifiable data.
          </p>
          <p>
            <strong>2.</strong> In relation to the controlling and processing of
            cookies, the cookies we use are:
          </p>
          <p>
            <strong>2.1. First-party Cookies</strong> - these cookies are set by
            us;
          </p>
          <p>
            <strong>2.2. Third-party Cookies</strong> - these cookies are from a
            domain different from the domain of the Digiprovest Platform you are
            visiting. These cookies are usually used to ease our advertising and
            marketing efforts.
          </p>
          <p>
            <strong>3. </strong>Depending on the time the relevant cookie
            exists, the cookies we use are:
          </p>
          <p>
            <strong>3.1. Session Cookies</strong> - they are temporary and
            expire once you close your browser;
          </p>
          <p>
            <strong>3.2. Persistent Cookies</strong> - they stay on your device
            for a fixed&nbsp; period of time or until you delete them. They help
            us recognize you as our current user, respectively Client, remember
            your preferences and improve the Digiprovest Platform functionality. In
            addition, the persistent cookies allow us to provide you with
            personalized advertisements and measure the effectiveness of the
            Digiprovest Platform`s functionality and advertising.
          </p>
          <h2 className="text-xl my-10 mx-4">V. Why Do We Use Cookies?</h2>
          <p>We use cookies for different purposes as listed below:&nbsp;</p>
          <p>
            <strong>1.</strong> To recognize you when you sign in to use the
            Digiprovest Services. This allows us to provide you with
            recommendations, display personalized content and provide other
            customized features and services;
          </p>
          <p>
            <strong>2.</strong> To keep a track of your specific preferences.
            This allows us to honor your likes and dislikes, such as your
            language and configuration preferences;
          </p>
          <p>
            <strong>3.</strong> To provide, develop, maintain, personalize,
            protect and improve the Digiprovest Platform, the Digiprovest Services
            and their content;&nbsp;
          </p>
          <p>
            <strong>4.</strong> To perform analytics, including to analyse and
            report on the use and performance of the Digiprovest Services and
            any advertisements displayed on or delivered by or through
            them;&nbsp;
          </p>
          <p>
            <strong>5.</strong> To protect against, identify and prevent fraud
            and other unlawful activity;&nbsp;
          </p>
          <p>
            <strong>6. </strong>To create aggregate data about the groups or
            categories of our users, respectively Clients (including advertising
            audiences), and for us, our partners and third-party service
            providers to deliver, target, offer, market, or personalise
            advertising promotions and new industry-first features, relating to
            the Digiprovest Services, as well as offerings to our Clients and
            partners (including limiting how often you see an advertisement);
          </p>
          <p>
            <strong>7.</strong> To report promotions and introduce new
            industry-first features, which allows us to measure and analyze the
            performance of the Digiprovest Services.
          </p>
          <h2 className="text-xl my-10 mx-4">VI. How Can You Manage Cookies?</h2>
          <p>
            <strong>1.</strong> You can control the use of cookies when you
            first visit the Digiprovest Platform via the options given to you on the
            Cookie Banner, at any time when you click on the Manage Cookies
            button above in the text of Section II., as well as at the
            individual browser level. If you choose not to activate any of the
            cookies categories or to later disable cookies, you may still visit
            the Digiprovest Platform, but your ability to use some features of the
            Digiprovest Services or areas of the Digiprovest Platform may be limited.
          </p>
          <p>
            <strong>2.</strong> You can generally activate or later deactivate
            the use of cookies through a functionality built into your web
            browser. The “Help” feature on most browsers will tell you how to
            prevent your browser from accepting new cookies, how to have the
            browser notify you when you receive a new cookie, how to block
            cookies and when the relevant cookies will expire. If you block all
            cookies on your browser, neither we, nor third parties will transfer
            cookies to your browser. If you do this, however, you may have to
            manually adjust some preferences every time you visit the Digiprovest
            Platform, and some of the Digiprovest Platform, Digiprovest Services or
            features thereof may not work. To learn more about how to control
            cookies settings through your browser:
          </p>
          <p>
            <strong>2.1.</strong> For Firefox “Private Browsing” managing
            cookies settings, click here: <br />
            <a
              href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&amp;redirectlocale=en-US"
              target="_blank"
              rel="noopener noreferrer "
            >
              https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&amp;redirectlocale=en-US
            </a>
          </p>
          <p>
            <strong>2.2.</strong> For Chrome “Incognito” managing cookies
            settings, click here: <br />
            <a
              href="https://support.google.com/chrome/answer/95647?hl=en"
              target="_blank"
              rel="noopener noreferrer "
            >
              https://support.google.com/chrome/answer/95647?hl=en
            </a>
          </p>
          <p>
            <strong>2.3.</strong> For Internet Explorer “InPrivate” managing
            cookies settings, click here:{" "}
            <a
              href="https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
              target="_blank"
              rel="noopener noreferrer "
            >
              https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d
            </a>
            &nbsp;&nbsp;&nbsp;
          </p>
          <p>
            <strong>2.4.</strong> For Safari for Mac managing cookies settings,
            click here: <br />
            <a
              href="https://support.apple.com/en-gb/guide/safari/sfri11471/mac"
              target="_blank"
              rel="noopener noreferrer "
            >
              https://support.apple.com/en-gb/guide/safari/sfri11471/mac
            </a>
          </p>
          <h2 className="text-xl my-10 mx-4">VII. Miscellaneous</h2>
          <p>
            <strong>1. </strong>The content of this Policy is for your general
            information and use only. You acknowledge and agree that it may
            contain inaccuracies or errors, and we expressly exclude liability
            for any such inaccuracies or errors to the fullest extent permitted
            by law.
          </p>
          <p>
            <strong>2.</strong> Digiprovest shall reserve its right to amend or
            supplement this Policy, including the above list of cookies, from
            time to time. Any such amendments or supplements shall become valid
            and in full force as of the date of their publishing on the Digiprovest
            Platform unless otherwise indicated. &nbsp;You shall regularly check
            the Digiprovest Platform to inform yourself about any such amendments or
            supplements, and your continued use of the Digiprovest Platform
            constitutes your agreement to be bound by the latter.&nbsp;
          </p>
          <p>
            <strong>3.</strong> The descriptive headings in this Policy are
            inserted for convenience only and shall not affect the
            interpretation thereof.
          </p>
          <p>
            <strong>4. </strong>The failure by Digiprovest to exercise any right or
            enforce any right or provision of this Policy shall not constitute a
            present or future waiver of such right or provision.&nbsp;
          </p>
          <p>
            <strong>5.</strong> None of your rights and obligations arising out
            of this Policy are assignable or transferable, without the prior
            written consent of Digiprovest. Digiprovest shall reserve the right to assign,
            delegate or transfer the rights and obligations under this Policy to
            any third party at any time, without notice or your consent.
          </p>
          <p>
            <strong>6.</strong> This Policy is prepared and concluded in English
            language. In case the Policy or any part of it thereof is translated
            into another language for whatever reason, the English version shall
            prevail.
          </p>
          <p>
            <strong>7. </strong>If you have questions or concerns regarding this
            Policy or the Digiprovest privacy practices, please contact us by email at
            support@Digiprovest.com.
          </p>{" "}
        </div>
      </main>
    </Layout>
  );
}
