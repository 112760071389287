export default function InvestmentPlan({ plan }) {
  return (
    <div
      className="pt-10 pr-10 pb-10 pl-10 mt-10 mr-0 mb-0 ml-0 bg-gray-900 text-white border-blue-300 col-span-2
          border-t-4 lg:mt-0 lg:col-span-1 bg-gradient"
    >
      <div className="flex flex-col pt-0 pr-0 pb-4 pl-0 border-blue-200 border-b">
        <div className="flex flex-col items-center">
          <p className="text-5xl text-blue-400 font-semibold">{plan.apr}%</p>
        </div>
        <p className="mt-5 mr-0 mb-0 ml-0 text-2xl font-medium capitalize">
          {plan.title}
        </p>
        {/* <p className="mt-4 mr-0 mb-0 ml-0 text-gray-500">{plan.info}</p> */}
      </div>
      <div className="mt-4 mr-3 mb-0 ml-3 space-y-3">
        <div className="flex flex-col font-medium text-gray-500">
          <p className="">Minimum Deposit</p>
          <div className="flex items-end gap-1">
            <span className="text-2xl font-semibold text-blue-500 ">
              {plan.minimumDeposit}
            </span>
            <span className="text-white text-sm mb-[2px]">USD</span>
          </div>
        </div>
        <div className="flex flex-col font-medium text-gray-500">
          <p className="">Maximum Deposit</p>
          <div className="flex items-end gap-1">
            <span className="text-2xl font-semibold text-blue-500 ">
              {plan.maximumDeposit ? plan.maximumDeposit : <span>&#8734;</span>}
            </span>
            <span className="text-white text-sm mb-[2px]">USD</span>
          </div>
        </div>
        <div className="flex flex-col font-medium text-gray-500">
          <p className="">Minimum withdrawal</p>
          <div className="flex items-end gap-1">
            <span className="text-2xl font-semibold text-blue-500 ">
              {plan.minimumWithdrawal}
            </span>
            <span className="text-white text-sm mb-[2px]">USD</span>
          </div>
        </div>
        <div className="flex flex-col font-medium text-gray-500">
          <p className="">Duration</p>
          <div className="flex items-end gap-1">
            <span className="text-2xl font-semibold text-blue-500 ">
              {plan.duration} weeks
            </span>
          </div>
        </div>
      </div>
      <a
        href="/login"
        className="flex w-full h-12 mt-8 mr-0 mb-0 ml-0 font-medium hover:bg-blue-500 hover:text-white  text-blue-500 border-2 border-blue-500
            rounded-full justify-center items-center"
      >
        Start Earning
      </a>
    </div>
  );
}
