import React, { useState, useRef } from "react";
import Button from "@mui/material/Button";
import CurrencySelect from "../CurrencySelect";
import Cookies from "js-cookie";
import { createStake } from "../../api/balance";
import { useAuth } from "../../context/auth";
import { coinsAbv } from "../../utils/coins";
import { Formik, Form, Field } from "formik";
import { CircularProgress } from "@mui/material";
import NotificationSystem from "react-notification-system";
import { validateBalance } from "../../utils/FormValidations";

export default function StakingPlan({
  apy,
  duration,
  minimum,
  maximum,
  title,
  info,
  daily,
  monthly,
  weekly,
}) {
  const notificationSystem = useRef();
  const [buttonState, setButtonState] = useState(false);
  const [seletedCurrency, setseletedCurrency] = useState("bitcoin");
  const { user } = useAuth();
  const addNotification = ({ title, message, level }) => {
    const notification = notificationSystem.current;

    notification.addNotification({
      title,
      message,
      level,
    });
  };

  const createNewStake = async (values) => {
    try {
      const token = Cookies.get("token");
      const response = await createStake(
        token,
        JSON.stringify({
          coin: coinsAbv[seletedCurrency],
          amount: values.amount.toString(),
          apy,
          duration,
        })
      );
      if (response.status === 201) {
        addNotification({
          title: "Success",
          message: "Stake successful",
          level: "success",
        });
      }
      setButtonState(false);
    } catch (err) {
      setButtonState(false);
      addNotification({
        title: "Error",
        message: "An error has occured",
        level: "error",
      });

    }
  };
  return (
    <div className=" text-slate-200 bg-black w-80 mt-8  rounded-md p-6 py-4 border border-gray-500">
      <NotificationSystem ref={notificationSystem} />
      <h3 className="text-center text-lg font-bold">{title}</h3>
      <p className="text-center font-bold mb-2">{info}</p>
      <Formik
        initialValues={{
          amount: 0,
        }}
        onSubmit={(values) => {
          setButtonState(true);
          createNewStake(values);
        }}
      >
        {({
          errors,
          touched,
          handleChange,
          handleBlur,
          values,
          validateOnChange,
          /* and other goodies */
        }) => (
          <Form className="form flex flex-col bg-black text-white rounded-sm w-full">
            <div className="w-full">
              <CurrencySelect
                seletedCurrency={seletedCurrency}
                setseletedCurrency={setseletedCurrency}
              />
              <p className="text-gray-200 font-medium text-xs mt-0 mr-0 mb-0 ml-0 block">
                Balance: $
                {user.balances[`${coinsAbv[seletedCurrency]}_dollar`].toFixed(
                  2
                )}
              </p>
            </div>
            <div className="w-full">
              <p className="text-gray-200 font-medium text-xs mt-4 mr-0 mb-1 ml-0 block">
                Amount
              </p>
              <Field
                type="number"
                name="amount"
                className="mb-4 rounded p-4 w-full bg-black border border-white text-white"
                placeholder="$100"
                onBlur={handleBlur}
                onChange={handleChange}
                required
                min={minimum}
                max={maximum}
                id="outlined-basic"
                validate={(value) =>
                  validateBalance(
                    value,
                    user.balances[`${coinsAbv[seletedCurrency]}_dollar`]
                  )
                }
              />
              <p className="text-xs text-right mb-4">
                ${minimum} - ${maximum}
              </p>
              <div className="text-red-400">
                {errors.amount && touched.amount && errors.amount}
              </div>
            </div>
            <div className="text-sm">
              <div className="flex justify-between">
                <p>Duration</p>
                <p className="text-blue-400">{duration} days</p>
              </div>
              <div className="flex justify-between items-baseline">
                <p>APY</p>
                <p className="text-green-500 text-xl">{apy}%</p>
              </div>

              <div className="flex justify-between">
                <p>Weekly Earnings</p>
                <p className="text-green-400">+{weekly}%</p>
              </div>
              <div className="flex justify-between">
                <p>Monthly Earnings</p>
                <p className="text-green-400">+{monthly}%</p>
              </div>
            </div>

            <div className="form-submit">
              {buttonState && (
                <div
                  className="border rounded-md mt-4 w-full pt-2.5 pr-3.5 pb-2.5 pl-3.5
                text-white bg-blue-700 hover:bg-blue-900 flex justify-center items-center"
                >
                  <CircularProgress size={25} />
                </div>
              )}
              {!buttonState && (
                <Button
                  disabled={buttonState}
                  className="bg-green-500 mt-4 w-full text-white hover:bg-blue-700"
                  type="submit"
                >
                  Stake
                </Button>
              )}
            </div>
            <div className="text-red-400 mt-4">
              {/* {authError ? <p>{authError} </p> : null} */}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
