import React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { coins } from "../../utils/coins";

export default function CurrencySelect({
  seletedCurrency,
  setseletedCurrency,
}) {
  const [currency, setCurrency] = React.useState("bitcoin");

  const handleChange = (event) => {
    setCurrency(event.target.value);
    setseletedCurrency && setseletedCurrency(event.target.value);
  };
  return (
    <FormControl sx={{ minWidth: 120 }} className="w-full">
      {/* <InputLabel id="demo-simple-select-helper-label" className="text-white">Currency</InputLabel> */}
      <Select
        labelId="demo-simple-select-helper-label"
        id="demo-simple-select-helper"
        value={currency}
        label="Age"
        onChange={handleChange}
        className="mb-2 p-0 rounded w-full h-16 border border-white text-white"
        size="medium"
      >
        {coins.map((coin) => (
          <MenuItem key={coin._name} value={coin._name}>
            <img
              className=" w-5 mr-3 inline-flex"
              src={`/${coin.id}-logo.png`}
              alt="coinimage"
            />
            {coin._name.toUpperCase()}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
