import React from "react";
import { CircularProgress } from "@mui/material";

export default function FormButton({ buttonState, value }) {
  return (
    <div className="form-submit">
      {buttonState && (
        <div
          className=" rounded-md mt-4 w-full pt-2.5 pr-3.5 pb-2.5 pl-3.5
                              text-white bg-blue-500 hover:bg-blue-700 flex justify-center items-center"
        >
          <CircularProgress size={25} />
        </div>
      )}
      {!buttonState && (
        <input
          className=" rounded-md mt-4 w-full pt-2.5 pr-3.5 pb-2.5 pl-3.5
                              text-white bg-blue-500 hover:bg-blue-700 cursor-pointer"
          type="submit"
          disabled={buttonState}
          value={value}
        />
      )}
    </div>
  );
}
