import Layout from "../../components/Layout";
import OTCFAQ from "../../components/FAQ/otc";
import FAQ from "../../components/FAQ";

export default function FAQPage() {
  return (
    <Layout>
      <main>
        <div className="bg-white py-6 px-6 md:px-16 lg:px-28 flex items-center justify-between">
          <div className="mx-auto pt-16 pr-4 pb-16 pl-4 md:px-24 lg:px-8 lg:py-20">
            <FAQ />
          </div>
        </div>
        <div className="bg-white py-6 px-6 md:px-16 lg:px-28 flex items-center justify-between">
          <div className="mx-auto pt-16 pr-4 pb-16 pl-4 md:px-24 lg:px-8 lg:py-20">
            <OTCFAQ />
          </div>
        </div>
      </main>
    </Layout>
  );
}
