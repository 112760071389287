import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import { getInvestments } from "../../api/balance";
import Cookies from "js-cookie";
import { useAuth } from "../../context/auth";
import LoadingScreen from "../LoadingScreen";
import Balances from "../Balances";
import { NavLink } from "react-router-dom";
import InvestmentCard from "../InvestmentCard";
import { investmentPlans } from "../../utils/investmentPlans";
import InvestCard from "../InvestCard";

export default function Investments() {
  const [stakesLoading, setStakesLoading] = useState(true);
  const [investments, setInvestments] = useState(null);
  const { user, loading } = useAuth();
  useEffect(() => {
    async function loadUserFromCookies() {
      const token = Cookies.get("token");
      if (token) {
        const _stakes = await getInvestments(token);
        if (_stakes) setInvestments(_stakes.data);
      }
      setStakesLoading(false);
    }
    loadUserFromCookies();
  }, []);
  if (loading || stakesLoading)
    return <LoadingScreen loading={loading || stakesLoading} />;

  return (
    <>
      <div className="flex flex-col max-w-sm  mb-8 border bg-black border-gray-500 rounded text-white">
        <h3 className="p-4 border-b border-gray-500 text-lg font-semibold">
          My Investments
        </h3>
        <NavLink to="/packages" className="p-4 flex gap-3 hover:bg-gray-900">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 48 48"
            height="48"
            width="48"
            data-element="Learn"
          >
            <path
              fill="#BFE9FF"
              d="M30 30c8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15-8.284 0-15 6.716-15 15 0 8.284 6.716 15 15 15z"
            ></path>
            <path
              fill="#56B4FC"
              d="M3 24c0-8.475 7.05-15.3 15.525-15C26.7 9.3 33 16.2 33 24.375V48H15v-9h-4.5c-1.65 0-3-1.35-3-3v-6H3v-6z"
            ></path>
            <path
              fill="#1652F0"
              d="M18.525 9c-.825 0-1.575 0-2.325.15-.75 1.8-1.2 3.825-1.2 5.85 0 8.25 6.75 15 15 15 1.05 0 2.025-.075 3-.3v-5.325C33 16.2 26.7 9.3 18.525 9z"
            ></path>
            <path
              fill="#fff"
              d="m24 13.5 1.05 3.45L28.5 18l-3.45 1.05L24 22.5l-1.05-3.45L19.5 18l3.45-1.05L24 13.5z"
            ></path>
          </svg>
          <div>
            <h4>Learn about Investing</h4>
            <p className="text-gray-500">See how we make gains</p>
          </div>
        </NavLink>
      </div>
      <Balances
        totalBalance={user.balances.total.toFixed(2)}
        investingBalance={user.balances.total_investments.toFixed(2)}
      />
      <Typography variant="h5" className="text-white my-8">
        Ongoing Investments
      </Typography>
      <div className="flex flex-wrap gap-4 flex-col md:flex-row">
        {investments.map((stake) => (
          <InvestCard
            title={stake.name}
            amount={stake.amount}
            apy={stake.roi}
            duration={stake.duration}
            coin={stake.coin}
            staking_id={stake.staking_id}
            key={stake.staking_id}
            start_date={stake.start_date}
            investment_id={stake.id}
          />
        ))}
      </div>
      <div>
        {investments.length <= 0 && (
          <div className="flex items-center justify-center border-dashed border border-gray-700 rounded-md">
            <Typography variant="h66" className="text-white my-8">
              No investments
            </Typography>
          </div>
        )}
      </div>
      <Typography variant="h5" className="text-white my-8">
        Investment Plans
      </Typography>
      <div className="md:grid md:grid-cols-2 lg:grid-cols-3 gap-x-10 gap-y-10">
        {investmentPlans.map((plan) => (
          <InvestmentCard
            apr={plan.apr}
            durationInWeeks={plan.duration}
            minimumDeposit={plan.minimumDeposit}
            maximumDeposit={plan.maximumDeposit}
            title={plan.title}
            key={plan.title}
            minimumWithdrawal={plan.minimumWithdrawal}
          />
        ))}
      </div>
    </>
  );
}
