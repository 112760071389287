import React, { useState, useRef } from "react";
import Button from "@mui/material/Button";
import CurrencySelect from "../CurrencySelect";
import Cookies from "js-cookie";
import { createInvestment } from "../../api/balance";
import { useAuth } from "../../context/auth";
import { coinsAbv } from "../../utils/coins";
import { Formik, Form, Field } from "formik";
import { CircularProgress } from "@mui/material";
import NotificationSystem from "react-notification-system";
import { validateBalance } from "../../utils/FormValidations";

export default function InvestmentCard({
  apr,
  title,
  durationInWeeks,
  minimumDeposit,
  maximumDeposit,
}) {
  const notificationSystem = useRef();
  const [buttonState, setButtonState] = useState(false);
  const [seletedCurrency, setseletedCurrency] = useState("bitcoin");
  const { user } = useAuth();
  const addNotification = ({ title, message, level }) => {
    const notification = notificationSystem.current;

    notification.addNotification({
      title,
      message,
      level,
    });
  };

  const createNewInvestment = async (values) => {
    try {
      const token = Cookies.get("token");
      const response = await createInvestment(
        token,
        JSON.stringify({
          coin: coinsAbv[seletedCurrency],
          amount: values.amount.toString(),
          roi: apr,
          name: title,
          duration: durationInWeeks * 7,
        })
      );
      if (response.status === 201) {
        addNotification({
          title: "Success",
          message: "Investment successful",
          level: "success",
        });
      }
      setButtonState(false);
    } catch (err) {
      setButtonState(false);
      addNotification({
        title: "Error",
        message: "An error has occured",
        level: "error",
      });
    }
  };
  return (
    <div
      className="pt-10 pr-10 pb-10 pl-10 mt-10 mr-0 mb-0 ml-0 bg-gray-900 text-white border-blue-300 col-span-2
          border-t-4 lg:mt-0 lg:col-span-1 bg-gradient"
    >
      <NotificationSystem ref={notificationSystem} />
      <div className="flex flex-col pt-0 pr-0 pb-4 pl-0 border-blue-200 border-b">
        <div className="flex flex-col items-center">
          <p className="text-5xl text-blue-400 font-semibold">{apr}%</p>
        </div>
        <p className="mt-5 mr-0 mb-0 ml-0 text-2xl font-medium capitalize">
          {title}
        </p>
        {/* <p className="mt-4 mr-0 mb-0 ml-0 text-gray-500">{info}</p> */}
      </div>
      <Formik
        initialValues={{
          amount: 0,
        }}
        onSubmit={(values) => {
          setButtonState(true);
          createNewInvestment(values);
        }}
      >
        {({
          errors,
          touched,
          handleChange,
          handleBlur,
          values,
          validateOnChange,
          /* and other goodies */
        }) => (
          <Form className="form flex flex-col text-white rounded-sm w-full">
            <div className="w-full">
              <CurrencySelect
                seletedCurrency={seletedCurrency}
                setseletedCurrency={setseletedCurrency}
              />
              <p className="text-gray-200 font-medium text-xs mt-0 mr-0 mb-0 ml-0 block">
                Balance: $
                {user.balances[`${coinsAbv[seletedCurrency]}_dollar`].toFixed(
                  2
                )}
              </p>
            </div>
            <div className="w-full">
              <p className="text-gray-200 font-medium text-xs mt-4 mr-0 mb-1 ml-0 block">
                Amount
              </p>
              <Field
                type="number"
                name="amount"
                className="mb-4 rounded p-4 w-full bg-black border border-white text-white"
                placeholder="$100"
                onBlur={handleBlur}
                onChange={handleChange}
                required
                min={minimumDeposit}
                max={maximumDeposit}
                id="outlined-basic"
                validate={(value) =>
                  validateBalance(
                    value,
                    user.balances[`${coinsAbv[seletedCurrency]}_dollar`]
                  )
                }
              />
              <p className="text-xs text-right mb-4">
                ${minimumDeposit} - ${maximumDeposit}
              </p>
              <div className="text-red-400">
                {errors.amount && touched.amount && errors.amount}
              </div>
            </div>
            <div className="flex justify-between text-sm">
              <p>Duration</p>
              <p className="text-blue-400">{durationInWeeks} weeks</p>
            </div>

            <div className="form-submit mt-2">
              {buttonState && (
                <div
                  className="border rounded-md mt-4 w-full pt-2.5 pr-3.5 pb-2.5 pl-3.5
                text-white bg-blue-700 hover:bg-blue-900 flex justify-center items-center"
                >
                  <CircularProgress size={25} />
                </div>
              )}
              {!buttonState && (
                <Button
                  disabled={buttonState}
                  className="bg-green-500 mt-4 w-full text-white hover:bg-blue-700"
                  type="submit"
                >
                  Start Earning
                </Button>
              )}
            </div>
            <div className="text-red-400 mt-4">
              {/* {authError ? <p>{authError} </p> : null} */}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
