import React, { useRef, useState } from "react";
import { useAuth } from "../../context/auth";
import Cookies from "js-cookie";
import { topUpInvestment } from "../../api/balance";
import { Field, Form, Formik } from "formik";
import { validateBalance } from "../../utils/FormValidations";
import { Button, CircularProgress } from "@mui/material";
import NotificationSystem from "react-notification-system";

function calculateEndDate(startDate, duration) {
  return new Date(startDate.getTime() + duration);
}
export default function InvestCard({
  amount,
  coin,
  apy,
  investment_id,
  duration,
  start_date,
  title,
}) {
  const notificationSystem = useRef();
  const [buttonState, setButtonState] = useState(false);
  const [seletedCurrency, setseletedCurrency] = useState("bitcoin");
  const { user } = useAuth();
  const addNotification = ({ title, message, level }) => {
    const notification = notificationSystem.current;

    notification.addNotification({
      title,
      message,
      level,
    });
  };

  const createNewInvestment = async (values) => {
    const newAmount = user.balances[`${coin}_dollar`] +values.amount
    console.log(newAmount)
    try {
      const token = Cookies.get("token");
      const response = await topUpInvestment(
        token,
        JSON.stringify({
          id: investment_id,
          amount: newAmount.toString(),
        })
      );
      if (response.status === 201) {
        addNotification({
          title: "Success",
          message: "Investment successful",
          level: "success",
        });
      }
      setButtonState(false);
    } catch (err) {
      setButtonState(false);
      addNotification({
        title: "Error",
        message: "An error has occured",
        level: "error",
      });
    }
  };
  let sd = new Date(start_date);
  let dur = duration * 1000 * 60 * 60 * 24;
  let dt = calculateEndDate(sd, dur).toDateString();
  return (
    <div className="border-1 w-64 bg-black border border-gray-500 rounded p-4 py-4">
      <NotificationSystem ref={notificationSystem} />
      <div className="flex gap-2">
        <p className="bg-blue-500 text-white py-1 text-sm px-4 mb-5 w-fit rounded-3xl">
          {title}
        </p>
        <p className="bg-blue-500 text-white py-1 text-sm px-4 mb-5 w-fit rounded-3xl">
          {coin}
        </p>
      </div>
      <div className="flex justify-between ">
        <div className="flex flex-col items-start leading-7 text-green-100">
          <p className="mr-2 text-3xl">${amount}</p>
        </div>
        <div>
          <p className="text-3xl text-green-400">{apy}%</p>
        </div>
      </div>
      {/* <Formik
        initialValues={{
          amount: 100,
        }}
        onSubmit={(values) => {
          setButtonState(true);
          createNewInvestment(values);
        }}
      >
        {({
          errors,
          touched,
          handleChange,
          handleBlur,
          values,
          validateOnChange,
        }) => (
          <Form className="form flex flex-col text-white rounded-sm w-full">
            <div className="w-full">
              <p className="text-gray-200 font-medium text-xs mt-4 mr-0 mb-1 ml-0 block">
                Amount
              </p>
              <Field
                required
                type="number"
                name="amount"
                className="mb-4 rounded p-4 w-full bg-black border border-white text-white"
                placeholder="$100"
                onBlur={handleBlur}
                onChange={handleChange}
                id="outlined-basic"
                validate={(value) =>
                  validateBalance(value, user.balances[`${coin}_dollar`])
                }
              />

              <div className="text-red-400">
                {errors.amount && touched.amount && errors.amount}
              </div>
            </div>
            <div className="w-full">
              <p className="text-gray-200 font-medium text-xs mt-0 mr-0 mb-0 ml-0 block">
                Balance: $
                {user.balances[`${coin}_dollar`] &&
                  user.balances[`${coin}_dollar`].toFixed(2)}
              </p>
            </div>
            <div className="form-submit mt-2">
              {buttonState && (
                <div
                  className="border rounded-md mt-4 w-full pt-2.5 pr-3.5 pb-2.5 pl-3.5
                text-white bg-blue-700 hover:bg-blue-900 flex justify-center items-center"
                >
                  <CircularProgress size={25} />
                </div>
              )}
              {!buttonState && (
                <Button
                  disabled={buttonState}
                  className="bg-green-500 mt-4 w-full text-white hover:bg-blue-700"
                  type="submit"
                >
                  Top Up
                </Button>
              )}
            </div>

          </Form>
        )}
      </Formik> */}
      <div className=" text-xs mt-4 pt-2 text-slate-100 border-t border-blue-400">
        Ends on {dt}
      </div>
    </div>
  );
}
