import * as React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import {
  AppBar,
  CssBaseline,
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import InboxIcon from "@mui/icons-material/Inbox";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ReceiptIcon from "@mui/icons-material/Receipt";
import LockClockIcon from "@mui/icons-material/LockClock";
import PaidIcon from "@mui/icons-material/Paid";
import SendIcon from "@mui/icons-material/Send";
import CallReceivedIcon from "@mui/icons-material/CallReceived";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import PersonIcon from "@mui/icons-material/Person";
import Balance from "../Balance";
import Account from "../Account";
import Receive from "../Receive";
import Send from "../Send";
import Swap from "../Swap";
import Staking from "../Staking";
import Investments from "../Investments";
import Transactions from "../Transactions";
import { useAuth } from "../../context/auth";
import AccountMenu from "../AccountMenu";
import { useDashboardSection } from "../../context/dashSection";
import DashboardFooter from "../DashboardFooter";
import OTC from "../OTC";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
const drawerWidth = 240;

function ResponsiveDrawer(props) {
  const { user, loading, logout } = useAuth();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { currentSection, setCurrentSection } = useDashboardSection();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const sections = [
    {
      title: "Balance",
      icon: AccountBalanceIcon,
      section: "balance",
    },
    {
      title: "Transactions",
      icon: ReceiptIcon,
      section: "transactions",
    },
    {
      title: "Investments",
      icon: PaidIcon,
      section: "investments",
    },
    {
      title: "Staking",
      icon: LockClockIcon,
      section: "staking",
    },
    {
      title: "Send",
      icon: SendIcon,
      section: "send",
    },
    {
      title: "Receive",
      icon: CallReceivedIcon,
      section: "receive",
    },
    {
      title: "Swap",
      icon: SwapHorizIcon,
      section: "swap",
    },
    {
      title: "Account",
      icon: PersonIcon,
      section: "account",
    },
    {
      title: "OTC",
      icon: CurrencyExchangeIcon,
      section: "otc",
    },
    // {
    //   title: "Logout",
    //   icon: InboxIcon,
    //   section: "logout",
    // },
  ];

  const drawer = (
    <div className="h-full bg-black text-white border-r-[1px] border-gray-600">
      <Toolbar>
        <NavLink to="/">
          <p className=" text-xl justify-self-start font-bold text-white tracking-wide uppercase">
            Digiprovest
          </p>
        </NavLink>
      </Toolbar>
      <List className="pt-0 mt-3 px-4">
        {sections.map((section) => (
          <ListItem
            className={`text-blue-200 my-1 rounded-[100px] ${
              currentSection === section.section &&
              `bg-[#001033] text-[#3773F5]  `
            } hover:bg-blue-900`}
            key={section.title}
            onClick={
              section.title === "Logout"
                ? () => logout()
                : () => setCurrentSection(section.section)
            }
            disablePadding
          >
            <ListItemButton>
              <ListItemIcon className="mr-2 ml-2 min-w-[32px]">
                <section.icon
                  className={`text-white ${
                    currentSection === section.section && ` text-[#3773F5] `
                  }`}
                />
              </ListItemIcon>
              <ListItemText
                className={`font-semibold text-white ${
                  currentSection === section.section && ` text-[#3773F5] `
                }`}
                primary={section.title}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <div to="/referral" className="px-4 self-end my-5 ">
        <NavLink
          to="/referral"
          className="flex p-4  rounded-2xl bg-gray-800 items-center cursor-pointer "
        >
          <img className="w-10 mr-2" src={`/giftbox-1.svg`} alt="gift" />
          <div>
            <p className="mb-[2px] text-white text-lg leading-5">Get $20</p>
            <p className="text-gray-500 text-sm ">Invite Friends</p>
          </div>
        </NavLink>
      </div>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;
  if (loading)
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          borderLeft: "0px solid rgb(0 0 0 / 85%)",
        }}
        className="border-0 shadow-none"
        elevation={0}
      >
        <Toolbar className="bg-black w-full  border-b-[1px] border-gray-600">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <div className="flex w-full items-center justify-between">
            <Typography
              variant="h7"
              noWrap
              component="div"
              className="text-sm md:text-base"
            >
              {currentSection.toUpperCase()}
            </Typography>
            <Box className="flex items-center">
              <Typography variant="h6" className="text-sm md:text-base mr-4">
                {user ? `Hi, ${user.username}!` : ""}
              </Typography>
              <a
                href="https://crypto.com/"
                target="_blank"
                rel="noreferrer"
                className="hidden md:block font-semibold py-2 text-white text-opacity-90 px-6 mx-5 cursor-pointer rounded-[100px] bg-[#3773F5] "
              >
                Buy Crypto
              </a>
              <AccountMenu setCurrentSection={setCurrentSection} />
            </Box>
          </div>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          elevation={0}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              borderRight: "0px ",
              backgroundColor: "black",
              paddingBottom: "16px",
            },
          }}
          className="border-0 "
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              borderRight: "0px",
              backgroundColor: "black",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          py: 12,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
        className="bg-black min-h-screen relative"
      >
        {currentSection === "balance" && <Balance />}
        {currentSection === "transactions" && <Transactions />}
        {currentSection === "investments" && <Investments />}
        {currentSection === "account" && <Account />}
        {currentSection === "receive" && <Receive />}
        {currentSection === "send" && <Send />}
        {currentSection === "staking" && <Staking />}
        {currentSection === "swap" && <Swap />}
        {currentSection === "otc" && <OTC />}
        <DashboardFooter />
      </Box>
    </Box>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;
