import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { NotificationProvider } from "./context/notif";
import { AuthProvider } from "./context/auth";
import { DashboardSectionProvider } from "./context/dashSection";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Router>
    <NotificationProvider>
      <AuthProvider>
        <DashboardSectionProvider>
          <App />
        </DashboardSectionProvider>
      </AuthProvider>
    </NotificationProvider>
  </Router>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
