import React, { useState, useRef } from "react";

import { Formik, Form, Field } from "formik";
import NotificationSystem from "react-notification-system";
import Layout from "../../components/Layout";
import { login, SignUserUp } from "../../api/auth";
import {
  validateConfirmPassword,
  validateEmail,
  validateName,
  validatePassword,
} from "../../utils/FormValidations";
import FormLabel from "../../components/FormLabel";
import FormError from "../../components/FormError";
import { CircularProgress } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const SignUp = () => {
  const notificationSystem = useRef();
  const addNotification = ({ title, message, level }) => {
    const notification = notificationSystem.current;

    notification.addNotification({
      title,
      message,
      level,
    });
  };

  const [authError, setAuthError] = useState(null);

  const [buttonState, setButtonState] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  //fields validation

  const signUserUp = async (values) => {
    let username = values.username;
    let email = values.email;
    let password = values.password;
    let first_name = values.firstname;
    let last_name = values.lastname;
    let referral_code = values.referral_code;
    try {
      const response = referral_code
        ? await SignUserUp(
            JSON.stringify({
              username,
              email,
              password,
              first_name,
              last_name,
              referral_code,
            })
          )
        : await SignUserUp(
            JSON.stringify({
              username,
              email,
              password,
              first_name,
              last_name,
            })
          );

      if (response.status === 200) {
        login(response?.data.token);
        addNotification({
          title: "Success",
          message: "You have successfully created an account",
          level: "success",
        });
        setTimeout(() => {
          window.location.pathname = "/login";
        }, 5000);
      }
      setButtonState(false);
      setAuthError(null);
    } catch (err) {
      setButtonState(false);

      if (!err.response.data.username) {
        setAuthError("An error has occurred");
        addNotification({
          title: "Error",
          message: "An error has occurred",
          level: "error",
        });
      } else {
        setAuthError(err.response.data.username);
        addNotification({
          title: "Error",
          message: err.response.data.username,
          level: "error",
        });
      }
      // setAuthError(null);
    }
  };

  // let token = localStorage.getItem('token');
  // let expiry = localStorage.getItem('expiry');
  // const expirydate = new Date(expiry)
  // if (token && Date.now() < expirydate.getTime() ) {
  //   return <Navigate to="/dashboard" replace />;
  // }
  return (
    <Layout>
      <NotificationSystem ref={notificationSystem} />
      <main>
        <div>
          <div className=" ">
            <div className="pt-24 pr-4 pb-24 pl-4">
              <div className="max-w-9xl mt-0 mr-auto mb-0 ml-auto">
                <div className="w-full mt-0 mr-auto border py-8 px-5 border-blue-100 rounded-3xl mb-0 ml-auto space-y-5 sm:w-9/12 md:w-7/12 lg:w-5/12 xl:w-4/12">
                  <p className="font-bold text-3xl text-blue-500 mb-4 text-center">
                    Sign Up
                  </p>
                  <Formik
                    initialValues={{
                      firstname: "",
                      lastname: "",
                      username: "",
                      email: "",
                      password: "",
                      confirmPassword: "",
                      referral_code: "",
                    }}
                    onSubmit={(values) => {
                      setButtonState(true);
                      signUserUp(values);
                    }}
                  >
                    {({
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      values,
                      validateOnChange,
                      /* and other goodies */
                    }) => (
                      <Form className="form">
                        <div className="flex gap-3 items-between">
                          <div className="w-2/4">
                            <FormLabel>Firstname</FormLabel>
                            <Field
                              type="text"
                              required
                              name="firstname"
                              className="border border-blue-400 w-full rounded-md border
                            hover:border-blue-600 pt-2 pr-3 pb-2 pl-3"
                              placeholder="John"
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                            <FormError
                              error={errors.firstname}
                              touched={touched.firstname}
                            />
                          </div>
                          <div className="w-2/4">
                            <FormLabel>Lastname</FormLabel>
                            <Field
                              required
                              type="text"
                              name="lastname"
                              className="border border-blue-400 w-full rounded-md border
                            hover:border-blue-600 pt-2 pr-3 pb-2 pl-3"
                              placeholder="Doe"
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                            <FormError
                              error={errors.lastname}
                              touched={touched.lastname}
                            />
                          </div>
                        </div>
                        <div className="block">
                          <FormLabel>Username</FormLabel>
                          <Field
                            required
                            type="text"
                            name="username"
                            className="border border-blue-400 w-full rounded-md 
                            hover:border-blue-600 pt-2 pr-3 pb-2 pl-3"
                            placeholder="Username*"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            validate={validateName}
                          />
                          <FormError
                            error={errors.username}
                            touched={touched.username}
                          />
                        </div>
                        <div className="block">
                          <FormLabel>Your Email</FormLabel>
                          <Field
                            required
                            type="email"
                            name="email"
                            className="border border-blue-400 w-full rounded-md 
                            hover:border-blue-600 pt-2 pr-3 pb-2 pl-3"
                            placeholder="Ex. james@bond.com"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            validate={validateEmail}
                          />
                          <FormError
                            error={errors.email}
                            touched={touched.email}
                          />
                        </div>
                        <div className="block">
                          <FormLabel>Password</FormLabel>
                          <div className="relative">
                            <Field
                              type={showPassword ? "text" : "password"}
                              name="password"
                              className="border border-blue-400 w-full rounded-md 
                            hover:border-blue-600 pt-2 pr-3 pb-2 pl-3"
                              placeholder="******"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              validate={validatePassword}
                            />
                            {!showPassword ? (
                              <Visibility
                                className="absolute right-2 top-2 cursor-pointer"
                                onClick={() =>
                                  setShowPassword(
                                    (showPassword) => !showPassword
                                  )
                                }
                              />
                            ) : (
                              <VisibilityOff
                                className="absolute right-2 top-2 cursor-pointer"
                                onClick={() =>
                                  setShowPassword(
                                    (showPassword) => !showPassword
                                  )
                                }
                              />
                            )}
                          </div>

                          {values.password.length < 8 && (
                            <div className="text-orange-400 text-xs">
                              password should be at least 8 characters
                            </div>
                          )}

                          <FormError
                            error={errors.passsword}
                            touched={touched.password}
                          />
                        </div>
                        <div className="block">
                          <FormLabel>Confirm Password</FormLabel>
                          <div className="relative">
                            <Field
                              required
                              type={showConfirmPassword ? "text" : "password"}
                              name="confirmPassword"
                              className="border border-blue-400 w-full rounded-md 
                            hover:border-blue-600 pt-2 pr-3 pb-2 pl-3"
                              placeholder="******"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              validate={(value) =>
                                validateConfirmPassword(values.password, value)
                              }
                            />
                            {!showConfirmPassword ? (
                              <Visibility
                                className="absolute right-2 top-2 cursor-pointer"
                                onClick={() => {
                                  setShowConfirmPassword(
                                    (showConfirmPassword) =>
                                      !showConfirmPassword
                                  );
                                }}
                              />
                            ) : (
                              <VisibilityOff
                                className="absolute right-2 top-2 cursor-pointer"
                                onClick={() => {
                                  setShowConfirmPassword(
                                    (showConfirmPassword) =>
                                      !showConfirmPassword
                                  );
                                }}
                              />
                            )}
                          </div>
                          <FormError
                            error={errors.confirmPassword}
                            touched={touched.confirmPassword}
                          />
                        </div>
                        <div className="block">
                          <FormLabel>Referral Code</FormLabel>

                          <Field
                            type="text"
                            name="referral_code"
                            className="border border-blue-400 w-full rounded-md 
                            hover:border-blue-600 pt-2 pr-3 pb-2 pl-3"
                            placeholder="A23EW"
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          <FormError
                            error={errors.referral_code}
                            touched={touched.referral_code}
                          />
                        </div>
                        <button
                          type="submit"
                          className="rounded-md mt-4 w-full pt-2.5 pr-3.5 pb-2.5 pl-3.5
                              text-white bg-blue-500 hover:bg-blue-700 cursor-pointer"
                        >
                          {buttonState ? (
                            <CircularProgress
                              className="text-white"
                              size={25}
                            />
                          ) : (
                            "Sign Up"
                          )}
                        </button>
                        {/* <FormButton buttonState={buttonState} value="Sign Up" /> */}

                        <div className="text-red-500 text-xs mt-4">
                          {authError ? <p>{authError} </p> : null}
                        </div>
                        <div className="mt-3 mr-0 mb-2 ml-0 text-center">
                          <a
                            href="/"
                            className="text-sm text-gray-700 underline hover:text-purple-700"
                          >
                            Forgot password
                          </a>
                        </div>
                        <div className="w-full text-center">
                          Already have an Account?{" "}
                          <span>
                            <a
                              href="/login"
                              className="text-sm text-gray-700 underline hover:text-purple-700"
                            >
                              Login
                            </a>
                          </span>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default SignUp;
