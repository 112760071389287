export const investmentPlans = [
  {
    title: "Silver",
    info: "bitcoin",
    apr: 35,
    duration: 16,
    minimumDeposit: 5000,
    maximumDeposit: 20000,
    minimumWithdrawal: 5000,
  },
  {
    title: "Ruby",
    info: "bitcoin",
    apr: 35.5,
    duration: 16,
    minimumDeposit: 20000,
    maximumDeposit: 50000,
    minimumWithdrawal: 5000,
  },
  {
    title: "Gold",
    info: "bitcoin",
    apr: 38,
    duration: 32,
    minimumDeposit: 50000,
    maximumDeposit: 100000,
    minimumWithdrawal: 5000,
  },
  {
    title: "Diamond",
    info: "bitcoin",
    apr: 40,
    duration: 32,
    minimumDeposit: 100000,
    maximumDeposit: 500000,
    minimumWithdrawal: 5000,
  },
  {
    title: "Sapphire",
    info: "bitcoin",
    apr: 45,
    duration: 40,
    minimumDeposit: 500000,
    maximumDeposit: 1000000,
    minimumWithdrawal: 5000,
  },
  {
    title: "Platinum",
    info: "Platinum package",
    apr: 50,
    duration: 52,
    minimumDeposit: 1000000,
    maximumDeposit: null,
    minimumWithdrawal: 5000,
  },
];
