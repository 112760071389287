import { get, post } from "./base";
import { Cookies } from "react-cookie";
import nextCookie from "next-cookies";

// set up cookies
const cookies = new Cookies();

export const handleAuthSSR = async (ctx) => {
  const { token } = nextCookie(ctx);

  try {
    if (!token) {
      return {};
    }
    const response = await get("/auth/userinfo", {
      headers: { Authorization: `Token ${token}` },
    });
    return response.data;
  } catch (error) {
    /* eslint-disable no-console */
    // console.log("Error: ", error);
    // Implementation or Network error
    return false;
  }
  //   return {};
};

export const SignUserUp = async (body) => {
  return await post("/auth/register/", body, {
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
    },
  });
};

export const LogUserIn = async (body) => {
  return await post("/auth/login/", body, {
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
    },
  });
};

export const changePassword = async (token, body) => {
  return await post("/auth/change-password/", body, {
    headers: {
      Authorization: `Token ${token}`,
      "Content-Type": "application/json",
      accept: "application/json",
    },
  });
};

export const getUser = async (token) => {
  return await get("/auth/userinfo/", {
    headers: { Authorization: `Token ${token}` },
  });
  // try {
  //   if (!token) {
  //     return {};
  //   }
  //   const response = await get("/auth/userinfo/", {
  //     headers: { Authorization: `Token ${token}` },
  //   });
  //   return response.data;
  // } catch (error) {
  //   console.log(error)
  //   return false;
  // }
};

export const getOTP = async (body) => {
  return await post("/auth/otp/", body, {
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
    },
  });
};

export const OTPVerify = async (token, body) => {
  return await post("/auth/verify/", body, {
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
    },
  });
};

export const login = async (token) => {
  //   Cookie will expire after 24h
  cookies.set("token", token, { maxAge: 60 * 60 * 24 });
};

export const logout = () => {
  cookies.remove("token");
};

export const VerifyKYC = async (token, body) => {
  return await post("/auth/kyc/", body, {
    headers: {
      Authorization: `Token ${token}`,
      "Content-Type": "multipart/form-data",
      Accept: "*/*",
    },
  });
};
export const GetKYC = async (token) => {
  return await get("/auth/kyc", {
    headers: { Authorization: `Token ${token}` },
  });
};
