import { NavLink } from "react-router-dom";
import Layout from "../../components/Layout";
import StakeCalculator from "../../components/StakeCalculator";
import StarIcon from "@mui/icons-material/Star";
import {
  TableContainer,
  TableHead,
  Table,
  TableRow,
  TableBody,
  TableCell,
} from "@mui/material";
import EthereumFAQ from "../../components/FAQ/ethereum-staking";
export default function EthereumStaking() {
  return (
    <Layout>
      <main className=" mt-5">
        <div className="px-4 bg-indigo-400 py-16 md:px-10 flex flex-col lg:flex-row gap-10 lg:gap-4 items-center">
          <div className="lg:w-1/2">
            <h2 className="text-7xl font-bold text-white max-w-lg">
              Ethereum Staking Pool
            </h2>
            <p className="text-white text-lg my-6">
              Stake to our own ETH pool with already over{" "}
              <strong>$7,000,000</strong> staked.
              <br />
              Join <strong>1329</strong> people who are already staking with us.
            </p>
            <p className="mb-6 text-white">
              Excellent Score 4.6 on Excellent Rating on{" "}
              <StarIcon className="text-green-500" /> TrustPilot
            </p>

            <NavLink to="/login" className="px-4 py-3 bg-indigo-800 text-white rounded-xl text-xl">
              Stake Ethereum
            </NavLink>
          </div>
          <div className="lg:w-1/2">
            <StakeCalculator coin="ethereum" apy={47} min={2} />
          </div>
        </div>
        <div className="px-4 py-16 md:px-10 gap-4 items-center">
          <h2 className="text-3xl font-bold text-indigo-600 mb-6">
            Staking Pool Stats
          </h2>
          <div className="flex flex-col lg:flex-row gap-4">
            <div className="lg:w-1/2">
              <div className="rounded-xl shadow-md p-4 mb-4">
                <div className="flex justify-between items-center pb-4 border-b-2 border-b-gray-100 mb-4">
                  <h4 className="text-2xl font-bold">Pool Deposits</h4>
                  <NavLink to="/login" className="bg-blue-500 text-white text-lg px-4 py-3 rounded-lg ">
                    Start Staking
                  </NavLink>
                </div>
                <div className="flex justify-between">
                  <div className="flex flex-col items-center">
                    <p className="text-sm font-light">APY</p>
                    <p className="text-2xl font-semibold">47%</p>
                  </div>
                  <div className="flex flex-col items-center">
                    <p className="text-sm font-light">LAUNCHED VALIDATORS</p>
                    <p className="text-2xl font-semibold">163</p>
                  </div>
                </div>
              </div>
              <div className="rounded-xl shadow-md p-4 mb-4">
                <div className="flex items-center pb-4 border-b-2 border-b-gray-100 mb-4">
                  <h4 className="text-2xl font-bold">Sent to the Pool</h4>
                </div>
                <div className="flex justify-between">
                  <div className="flex flex-col items-center">
                    <p className="text-sm font-light">TOTAL STAKED</p>
                    <p className="text-2xl font-semibold">6432 ETH</p>
                  </div>
                  <div className="flex flex-col items-center">
                    <p className="text-sm font-light">STAKERS</p>
                    <p className="text-2xl font-semibold">2180</p>
                  </div>
                </div>
              </div>
              <div className="rounded-xl shadow-md p-4 mb-4">
                <div className="flex justify-between items-center pb-4 border-b-2 border-b-gray-100 mb-4">
                  <h4 className="text-2xl font-bold">Generated Rewards</h4>
                  <h4 className="text-3xl font-bold">732 ETH</h4>
                </div>
                <div className="flex justify-between">
                  <div className="flex flex-col items-center">
                    <p className="text-sm font-light">ACTIVATED</p>
                    <p className="text-2xl font-semibold">6205 ETH</p>
                  </div>
                  <div className="flex flex-col items-center">
                    <p className="text-sm font-light">LAUNCHED VALIDATORS</p>
                    <p className="text-2xl font-semibold">227 ETH</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="lg:w-1/2">
              <TableContainer className="max-h-[425px] overflow-y-scroll rounded-xl shadow-md">
                <Table stickyHeader aria-label="sticky table">
                  <TableHead className="">
                    <TableRow>
                      <TableCell>Pubkey</TableCell>
                      <TableCell>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow className="text-black " hover tabIndex={-1}>
                      <TableCell className=" border-b-[#ffffff29] text-base md:text-lg max-w-[300px] overflow-scroll">
                        <a href="https://beaconscan.com/validator/0xb9f7fb73c642d5690cd1240e1478eea5d980dcb895ac9420501c320bc2020de82c93ab762cacf39b26c759fedd3404f0">
                          0xb9f7fb73c642d5690cd1240e1478eea5d980dcb895ac9420501c320bc2020de82c93ab762cacf39b26c759fedd3404f0
                        </a>
                      </TableCell>
                      <TableCell className="t border-b-[#ffffff29] text-base md:text-lg">
                        <div className="text-xs p-2 bg-green-100 text-green-600 rounded-lg">
                          Activated/online
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow className="text-black " hover tabIndex={-1}>
                      <TableCell className=" border-b-[#ffffff29] text-base md:text-lg max-w-[300px] overflow-scroll">
                        <a href="https://beaconscan.com/validator/0x8ec33097d1144c4e69e0c88bd9a200b57056d04a41d014f3295997b4715d4a1c84bb17b72ae82904d3e14dbc53981fdd">
                          0x8ec33097d1144c4e69e0c88bd9a200b57056d04a41d014f3295997b4715d4a1c84bb17b72ae82904d3e14dbc53981fdd
                        </a>
                      </TableCell>
                      <TableCell className="t border-b-[#ffffff29] text-base md:text-lg">
                        <div className="text-xs p-2 bg-green-100 text-green-600 rounded-lg">
                          Activated/online
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow className="text-black " hover tabIndex={-1}>
                      <TableCell className=" border-b-[#ffffff29] text-base md:text-lg max-w-[300px] overflow-scroll">
                        <a href="https://beaconscan.com/validator/0x8e3e2a41697cbe86841ec54fd7dc3d99e6461d8c09c63a31422887926efc9469f1cf6d42c056554364df2d5ee4813e54">
                          0x8e3e2a41697cbe86841ec54fd7dc3d99e6461d8c09c63a31422887926efc9469f1cf6d42c056554364df2d5ee4813e54
                        </a>
                      </TableCell>
                      <TableCell className="t border-b-[#ffffff29] text-base md:text-lg">
                        <div className="text-xs p-2 bg-green-100 text-green-600 rounded-lg">
                          Activated/online
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow className="text-black " hover tabIndex={-1}>
                      <TableCell className=" border-b-[#ffffff29] text-base md:text-lg max-w-[300px] overflow-scroll">
                        <a href="https://beaconscan.com/validator/0x8e07fc4714cef3cfa044c997310736b504197b6003f7d75c49fc49a58377ae62af633d188a8f31c415707073c2b05850">
                          0x8e07fc4714cef3cfa044c997310736b504197b6003f7d75c49fc49a58377ae62af633d188a8f31c415707073c2b05850
                        </a>
                      </TableCell>
                      <TableCell className="t border-b-[#ffffff29] text-base md:text-lg">
                        <div className="text-xs p-2 bg-green-100 text-green-600 rounded-lg">
                          Activated/online
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow className="text-black " hover tabIndex={-1}>
                      <TableCell className=" border-b-[#ffffff29] text-base md:text-lg max-w-[300px] overflow-scroll">
                        <a href="https://beaconscan.com/validator/0x8db9fd803d346c0ae8aa38ed4cdb66b331080b1b61f2c8357327d0ce705a86cba3b2aeea25dc65d55160106cb8a96330">
                          0x8db9fd803d346c0ae8aa38ed4cdb66b331080b1b61f2c8357327d0ce705a86cba3b2aeea25dc65d55160106cb8a96330
                        </a>
                      </TableCell>
                      <TableCell className="t border-b-[#ffffff29] text-base md:text-lg">
                        <div className="text-xs p-2 bg-green-100 text-green-600 rounded-lg">
                          Activated/online
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow className="text-black " hover tabIndex={-1}>
                      <TableCell className=" border-b-[#ffffff29] text-base md:text-lg max-w-[300px] overflow-scroll">
                        <a href="https://beaconscan.com/validator/0xb9897a100b8c4358d2450b8a0c39d9ed05cf46f21f54724e43d05b662569a1da39ccd145c6876f03ec7a48051697c762">
                          0xb9897a100b8c4358d2450b8a0c39d9ed05cf46f21f54724e43d05b662569a1da39ccd145c6876f03ec7a48051697c762
                        </a>
                      </TableCell>
                      <TableCell className="t border-b-[#ffffff29] text-base md:text-lg">
                        <div className="text-xs p-2 bg-green-100 text-green-600 rounded-lg">
                          Activated/online
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow className="text-black " hover tabIndex={-1}>
                      <TableCell className=" border-b-[#ffffff29] text-base md:text-lg max-w-[300px] overflow-scroll">
                        <a href="https://beaconscan.com/validator/0xb909b39d2bccf8df7eb8a60f7b2c51cd415d467a7bb3285520fc32bb7ca23a4943654ef48c3402efe0e4964900ea0ff8">
                          0xb909b39d2bccf8df7eb8a60f7b2c51cd415d467a7bb3285520fc32bb7ca23a4943654ef48c3402efe0e4964900ea0ff8
                        </a>
                      </TableCell>
                      <TableCell className="t border-b-[#ffffff29] text-base md:text-lg">
                        <div className="text-xs p-2 bg-green-100 text-green-600 rounded-lg">
                          Activated/online
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow className="text-black " hover tabIndex={-1}>
                      <TableCell className=" border-b-[#ffffff29] text-base md:text-lg max-w-[300px] overflow-scroll">
                        <a href="https://beaconscan.com/validator/0xb891631bd10cac3b47046f8538d85215fc9476fe129a6ecd4b82b41088fc8404cd932e5f5032e47caa76aa1b4bf3cf19">
                          0xb891631bd10cac3b47046f8538d85215fc9476fe129a6ecd4b82b41088fc8404cd932e5f5032e47caa76aa1b4bf3cf19
                        </a>
                      </TableCell>
                      <TableCell className="t border-b-[#ffffff29] text-base md:text-lg">
                        <div className="text-xs p-2 bg-green-100 text-green-600 rounded-lg">
                          Activated/online
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow className="text-black " hover tabIndex={-1}>
                      <TableCell className=" border-b-[#ffffff29] text-base md:text-lg max-w-[300px] overflow-scroll">
                        <a href="https://beaconscan.com/validator/0xb7d5fd7d2b2bd5368917391c47eb0c466b0234b701a797354aefb2cef1af8bebda4af0c55712119036c67b320c698890">
                          0xb7d5fd7d2b2bd5368917391c47eb0c466b0234b701a797354aefb2cef1af8bebda4af0c55712119036c67b320c698890
                        </a>
                      </TableCell>
                      <TableCell className="t border-b-[#ffffff29] text-base md:text-lg">
                        <div className="text-xs p-2 bg-green-100 text-green-600 rounded-lg">
                          Activated/online
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow className="text-black " hover tabIndex={-1}>
                      <TableCell className=" border-b-[#ffffff29] text-base md:text-lg max-w-[300px] overflow-scroll">
                        <a href="https://beaconscan.com/validator/0xb7c011f599060141a25cfa65fad7261828158f869f4412eb4fbf49a2e3d69e348e2f2c13d6e5d17030351e255262f0dc">
                          0xb7c011f599060141a25cfa65fad7261828158f869f4412eb4fbf49a2e3d69e348e2f2c13d6e5d17030351e255262f0dc
                        </a>
                      </TableCell>
                      <TableCell className="t border-b-[#ffffff29] text-base md:text-lg">
                        <div className="text-xs p-2 bg-green-100 text-green-600 rounded-lg">
                          Activated/online
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow className="text-black " hover tabIndex={-1}>
                      <TableCell className=" border-b-[#ffffff29] text-base md:text-lg max-w-[300px] overflow-scroll">
                        <a href="https://beaconscan.com/validator/0xb7bd261a33dc4818e7dcc6a560e20025626ee1dd626f995357b62cb5fe98045c80867fb4ebff8aff876db32b1ee4b4ab">
                          0xb7bd261a33dc4818e7dcc6a560e20025626ee1dd626f995357b62cb5fe98045c80867fb4ebff8aff876db32b1ee4b4ab
                        </a>
                      </TableCell>
                      <TableCell className="t border-b-[#ffffff29] text-base md:text-lg">
                        <div className="text-xs p-2 bg-green-100 text-green-600 rounded-lg">
                          Activated/online
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow className="text-black " hover tabIndex={-1}>
                      <TableCell className=" border-b-[#ffffff29] text-base md:text-lg max-w-[300px] overflow-scroll">
                        <a href="https://beaconscan.com/validator/0xb78ab4d6db530b358388f0e813822808594b64f2b41aa28130d987892e04aad04b18848c262ab0e13efd7d4a9ed1814d">
                          0xb78ab4d6db530b358388f0e813822808594b64f2b41aa28130d987892e04aad04b18848c262ab0e13efd7d4a9ed1814d
                        </a>
                      </TableCell>
                      <TableCell className="t border-b-[#ffffff29] text-base md:text-lg">
                        <div className="text-xs p-2 bg-green-100 text-green-600 rounded-lg">
                          Activated/online
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow className="text-black " hover tabIndex={-1}>
                      <TableCell className=" border-b-[#ffffff29] text-base md:text-lg max-w-[300px] overflow-scroll">
                        <a href="https://beaconscan.com/validator/0xb74862350df34c8d62375384e1e2da2f11ca1b46b0c62196ee555b31a49f51fd9753f27c8dfad61bf05a58375ac1515d">
                          0xb74862350df34c8d62375384e1e2da2f11ca1b46b0c62196ee555b31a49f51fd9753f27c8dfad61bf05a58375ac1515d
                        </a>
                      </TableCell>
                      <TableCell className="t border-b-[#ffffff29] text-base md:text-lg">
                        <div className="text-xs p-2 bg-green-100 text-green-600 rounded-lg">
                          Activated/online
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
        <div className="px-4 py-16 md:px-10 gap-4 items-center">
          <EthereumFAQ />
        </div>
      </main>
    </Layout>
  );
}
