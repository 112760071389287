import * as React from "react";
import { NavLink } from "react-router-dom";

function DashboardFooter() {
  return (
    <div className=" border-0 fixed bottom-0 left-0 md:left-[240px] w-full bg-neutral-900 z-50 p-5">
      <NavLink to="/" className="text-blue-500 mr-3 text-base">
        Home
      </NavLink>
      <NavLink to="/careers" className="text-blue-500 mr-3 text-base">
        Careers
      </NavLink>
      <NavLink to="/privacy-policy" className="text-blue-500 mr-3 text-base">
        Privacy
      </NavLink>
      <span className="text-neutral-500  text-base">© Digiprovest 2020</span>
    </div>
  );
}

export default DashboardFooter;
