import React from "react";
import Faq from "react-faq-component";

const data = {
  title: "FAQ",
  rows: [
    {
      title: "What is Ethereum 2.0?",
      content: `Ethereum 2.0 brings Proof of Stake to the Ethereum network and it is now possible to stake ETH and get rewards. Participating in ETH 2.0 staking requires 32 ETH and validator construction technology but Guarda made it all nice and simple for you.`,
    },
    {
      title: "How to stake Ethereum 2.0?",
      content:
        "If you don't want or can spend 32 ETH on running your own Ethereum staking validator. You can easily join Digiprovest's ETH staking pool and start staking Ether with as little as 0.1 ETH. ",
    },
    {
      title: "What's the interest rate?",
      content: `The annual interest rate is currently 47% and depends on the total staked amount in the network.`,
    },
    {
      title: "What is the minimal amount of stake?",
      content: `The minimal stake is 0.1 ETH.`,
    },
    {
      title: "How much is the service fee?",
      content: `The service fee is 10% of on-chain rewards.`,
    },
    {
      title: " How to withdraw staked ETH?",
      content: `According to the Ethereum staking rules, staked Ether and rewards are frozen in the network until the duration of the stake is exhausted thus currently it is impossible to withdraw ETH.`,
    },
  ],
};

const styles = {
  // bgColor: 'white',
  //   titleTextColor: "blue",
  //   rowTitleColor: "blue",
  // rowContentColor: 'grey',
  // arrowColor: "red",
};

const config = {
  animate: true,
  // arrowIcon: "V",
  // tabFocus: true
};

export default function EthereumFAQ() {
  return (
    <div>
      <Faq data={data} styles={styles} config={config} />
    </div>
  );
}
