import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import StakingPlan from "../StakingPlan";
import { getStakes } from "../../api/balance";
import Cookies from "js-cookie";
import { useAuth } from "../../context/auth";
import LoadingScreen from "../LoadingScreen";
import StakeCard from "../StakeCard";
import Balances from "../Balances";
import { NavLink } from "react-router-dom";

export default function Staking() {
  const [stakesLoading, setStakesLoading] = useState(true);
  const [stakes, setStakes] = useState(null);
  const { user, loading } = useAuth();

  useEffect(() => {
    async function loadUserFromCookies() {
      const token = Cookies.get("token");
      if (token) {
        const _stakes = await getStakes(token);
        if (_stakes) setStakes(_stakes.data);
      }
      setStakesLoading(false);
    }
    loadUserFromCookies();
  }, []);
  if (loading || stakesLoading)
    return <LoadingScreen loading={loading || stakesLoading} />;

  return (
    <>
      <div className="flex flex-col max-w-sm  mb-8 border bg-black border-gray-500 rounded text-white">
        <h3 className="p-4 border-b border-gray-500 text-lg font-semibold">
          My Stakings
        </h3>
        <NavLink to="/staking" className="p-4 flex gap-3 hover:bg-gray-900">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 48 48"
            height="48"
            width="48"
            data-element="Learn"
          >
            <path
              fill="#BFE9FF"
              d="M30 30c8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15-8.284 0-15 6.716-15 15 0 8.284 6.716 15 15 15z"
            ></path>
            <path
              fill="#56B4FC"
              d="M3 24c0-8.475 7.05-15.3 15.525-15C26.7 9.3 33 16.2 33 24.375V48H15v-9h-4.5c-1.65 0-3-1.35-3-3v-6H3v-6z"
            ></path>
            <path
              fill="#1652F0"
              d="M18.525 9c-.825 0-1.575 0-2.325.15-.75 1.8-1.2 3.825-1.2 5.85 0 8.25 6.75 15 15 15 1.05 0 2.025-.075 3-.3v-5.325C33 16.2 26.7 9.3 18.525 9z"
            ></path>
            <path
              fill="#fff"
              d="m24 13.5 1.05 3.45L28.5 18l-3.45 1.05L24 22.5l-1.05-3.45L19.5 18l3.45-1.05L24 13.5z"
            ></path>
          </svg>
          <div>
            <h4>Learn about Staking</h4>
            <p className="text-gray-500">See how we calculate rewards</p>
          </div>
        </NavLink>
      </div>
      <Balances
        totalBalance={user.balances.total.toFixed(2)}
        stakingBalance={user.balances.total_stakes.toFixed(2)}
      />
      <Typography variant="h5" className="text-white my-8">
        Current Stakes
      </Typography>
      <div className="flex flex-wrap gap-4 flex-col md:flex-row">
        {stakes.map((stake) => (
          <StakeCard
            amount={stake.amount}
            apy={stake.apy}
            duration={stake.duration}
            coin={stake.coin}
            staking_id={stake.staking_id}
            key={stake.staking_id}
            start_date={stake.start_date}
          />
        ))}
      </div>
      <div>
        {stakes.length <= 0 && (
          <div className="flex items-center justify-center border-dashed border border-gray-700 rounded-md">
            <Typography variant="h66" className="text-white my-8">
              No Current stakes
            </Typography>
          </div>
        )}
      </div>
      <Typography variant="h5" className="text-white my-8">
        Staking Plans
      </Typography>
      <div className="flex flex-wrap justify-between md:justify-around">
        <StakingPlan
          apy={50}
          duration="30"
          minimum={5000}
          maximum={25000}
          title="Bronze"
          info="Entry-level staking tier"
          // daily={0.1}
          weekly={0.92}
          monthly={32.66}
        />
        <StakingPlan
          apy={67}
          duration="60"
          minimum={25000}
          maximum={100000}
          title="Platinum"
          info="Mid-tier staking"
          weekly={1.27}
          monthly={47.2}
        />
        <StakingPlan
          apy={84}
          duration="90"
          minimum={100000}
          maximum={500000}
          title="Diamond"
          info="High-end staking"
          weekly={1.6}
          monthly={69.46}
        />
      </div>
      <Container
        maxWidth="lg"
        sx={{ mt: 4, mb: 4 }}
        className="flex justify-center items-center px-0"
      ></Container>
    </>
  );
}
