import React from "react";

function calculateEndDate(startDate, duration) {
  return new Date(startDate.getTime() + duration);
}
export default function StakeCard({
  amount,
  coin,
  apy,
  staking_id,
  duration,
  start_date,
}) {
  let sd = new Date(start_date);
  let dur = duration * 1000 * 60 * 60 * 24;
  let dt = calculateEndDate(sd, dur).toDateString();
  return (
    <div className="border-1 w-64 bg-black border border-gray-500 rounded p-4 py-2">
      <p className="text-xs mb-4 pb-2 text-slate-100 border-b border-blue-400">
        ID: {staking_id}
      </p>
      <div className="flex justify-between ">
        <div className="flex flex-col items-start leading-7 text-green-100">
          <p className="mr-2 text-3xl">${amount}</p>
          <p className="">{coin}</p>
        </div>
        <div>
          <p className="text-3xl text-green-400">{apy}%</p>
        </div>
      </div>
      <div className=" text-xs mt-4 pt-2 text-slate-100 border-t border-blue-400">
        Ends on {dt}
      </div>
    </div>
  );
}
