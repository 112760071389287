import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Grid, Typography } from "@mui/material";
import { currencyFormatter } from "../../utils/coins";

export default function SwapsTable({ transactions }) {
  return (
    <Grid container spacing={3} className="ml-0 mb-24 flex flex-col">
      <Typography variant="h5" className="text-white my-4 ">
        Swaps
      </Typography>
      <TableContainer
        component={Paper}
        className="bg-[#00234E] max-w-xs  md:max-w-[100%]"
      >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className="text-white">Date</TableCell>
              <TableCell className="text-white" align="right">
                Amount
              </TableCell>
              <TableCell className="text-white" align="right">
                From
              </TableCell>
              <TableCell className="text-white" align="right">
                To
              </TableCell>
              <TableCell className="text-white" align="right">
                Status
              </TableCell>
              {/* <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {transactions.length <= 0 && (
              <TableRow>
                <TableCell colSpan={5} className="text-white text-center">
                  No Transaction yet
                </TableCell>
              </TableRow>
            )}
            {transactions.map((row) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  className="text-white border-b-[#ffffff29]"
                  component="th"
                  scope="row"
                >
                  {new Date(row.time).toDateString()}
                </TableCell>
                <TableCell
                  className="text-white border-b-[#ffffff29]"
                  align="right"
                >
                  {currencyFormatter.format(row.amount)}
                </TableCell>
                <TableCell
                  className="text-white border-b-[#ffffff29]"
                  align="right"
                >
                  {row.converted_from}
                </TableCell>
                <TableCell
                  className="text-white border-b-[#ffffff29]"
                  align="right"
                >
                  {row.converted_to}
                </TableCell>
                <TableCell
                  className="text-white border-b-[#ffffff29] "
                  align="right"
                >
                  <div className="flex items-center justify-end">
                    <div
                      className={`w-2 h-2 rounded-full ${
                        row.status === "PENDING"
                          ? "bg-yellow-300"
                          : row.status === "SUCCESS"
                          ? "bg-green-300"
                          : "bg-red-300"
                      } mr-2`}
                    ></div>
                    <span>{row.status}</span>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}
