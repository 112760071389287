// TradingViewWidget.jsx
import React, { useEffect, useRef, memo } from "react";

function TradingViewWidget() {
  const container = useRef();

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js";
    script.type = "text/javascript";
    script.async = true;
    script.innerHTML = `
    {
        "symbols":[
            {
              "description": "Ethereum",
              "proName": "BINANCE:ETHUSD"
            },
            {
              "description": "Bitcoin",
              "proName": "BINANCE:BTCUSD"
            },
            {
              "description": "Litecoin",
              "proName": "BINANCE:LTCUSD"
            },
            {
              "description": "Solana",
              "proName": "BINANCE:SOLUSD"
            },
            {
              "description": "Ripple",
              "proName": "BINANCE:XRPUSD"
            }
          ],
        "showSymbolLogo": true,
        "colorTheme": "light",
        "isTransparent": false,
        "displayMode": "adaptive",
        "locale": "en"
         }`;
    container.current.appendChild(script);
  }, []);

  return (
    <nav id="ticker-tape" className="mt-8">
      <div className="tradingview-widget-container" ref={container}>
        <div className="tradingview-widget-container__widget"></div>
      </div>
    </nav>
  );
}

export default memo(TradingViewWidget);
