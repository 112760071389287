import axios from "axios";
import { getCookie } from "cookies-next";

const apiClient = axios.create({
  baseURL: "https://invapi.xyz/api/", // <- ENV variable
});
const token = getCookie("token");
apiClient.interceptors.request.use(
  (config) => {
    return {
      headers: {
        Authorization: `Token ${token}`,
      },
      ...config,
    };
  }
  // (error) => {
  //   console.log(error);
  //   Promise.reject(error);
  // }
);

// apiClient.interceptors.response.use((response) =>
//   response,
//   async (error) => {
//     ...
//     return Promise.reject(error.response.data);
//   },
// );

const { get, post, put, delete: destroy } = apiClient;
export { get, post, put, destroy };
