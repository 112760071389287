import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { useAuth } from "../../context/auth";
import { getTransactions } from "../../api/balance";
import LoadingScreen from "../LoadingScreen";
import Depositstable from "../DepositsTable";
import WithdrawalsTable from "../WithdrawalssTable";
import SwapsTable from "../SwapsTable";
import { Tab } from "@headlessui/react";
import TransactionsTab from "../TransactionsTab";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function Transactions() {
  const [transactions, setTransactions] = useState(["", ""]);
  const [transactionsLoading, setTransactionsLoading] = useState(true);
  const { loading } = useAuth();
  useEffect(() => {
    async function loadUserFromCookies() {
      const token = Cookies.get("token");
      if (token) {
        const txns = await getTransactions(token);
        if (txns) setTransactions(txns.data);
      }
      setTransactionsLoading(false);
    }
    loadUserFromCookies();
  }, []);
  if (loading || transactionsLoading)
    return <LoadingScreen loading={loading || transactionsLoading} />;
  return (
    <div className="max-w-4xl">
      <div className="w-full md:px-2 pb-16">
        <Tab.Group>
          <Tab.List className="flex space-x-1 rounded-xl bg-blue-700/20 p-1 ">
            <TransactionsTab title="Deposits" />
            <TransactionsTab title="Withdrawals" />
            <TransactionsTab title="Swaps" />
          </Tab.List>
          <Tab.Panels className="mt-2">
            <Tab.Panel
              className={classNames(
                "rounded-xl p-3",
              )}
            >
              {transactions.deposits && (
                <Depositstable transactions={transactions.deposits} />
              )}
            </Tab.Panel>
            <Tab.Panel
              className={classNames(
                "rounded-xl p-3",
              )}
            >
              {transactions.withdrawals && (
                <WithdrawalsTable transactions={transactions.withdrawals} />
              )}
            </Tab.Panel>
            <Tab.Panel
              className={classNames(
                "rounded-xl p-3",
              )}
            >
              {transactions.swap && (
                <SwapsTable transactions={transactions.swap} />
              )}
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  );
}
