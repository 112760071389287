import React, { useState, useEffect } from "react";
import { getPrices } from "../../api/balance";
import LoadingScreen from "../LoadingScreen";
import {  coinsAbv, currencyFormatter } from "../../utils/coins";
export default function StakeCalculator({ coin, apy, min }) {
  const [prices, setPrices] = useState([]);
  const [balanceLoading, setBalanceLoading] = useState(true);
  const [amount, setAmount] = useState(min);
  const onChange = (event) => {
    setAmount(event.target.value);
  };

  useEffect(() => {
    async function loadPrices() {
      const _prices = await getPrices();
      setPrices(_prices.data);
      setBalanceLoading(false);
    }
    loadPrices();
  }, []);
  if (balanceLoading) return <LoadingScreen loading={balanceLoading} />;
  return (
    <div className="bg-indigo-500 text-white w-full  p-4 shadow h-min  rounded-lg">
      <div className="text-white flex justify-between items-center mb-5 text-3xl font-bold">
        <h4>Calculate {coinsAbv[coin]} Rewards</h4>
        <h4>{apy}% APY</h4>
      </div>
      <div className="mb-3">
        <p>Enter your {coinsAbv[coin]} amount</p>{" "}
      </div>
      <input
        type="number"
        min={min}
        value={amount}
        onChange={onChange}
        className="w-full bg-inherit border border-indigo-100 rounded-lg px-4 py-3 mb-1"
      />
      <p className="text-xs text-indigo-200 font-bold mb-9">
        minimum: {min} {coin}
      </p>
      <div className="flex flex-col md:flex-row gap-3 md:gap-0 justify-between">
        <div className="flex flex-col items-center">
          <span className="text-sm">CURRENT VALUE</span>
          <p className="font-bold text-2xl">
            {currencyFormatter.format(prices[coin].usd * amount)}
          </p>
          <p>
            {amount} {coinsAbv[coin]}
          </p>
        </div>
        <div className="flex flex-col items-center">
          <span className="text-sm">EST. MONTHLY EARNING</span>
          <p className="font-bold text-2xl">
            {currencyFormatter.format(
              ((apy / 100) * (prices[coin].usd * amount)) / 12
            )}
          </p>
          <p>
            {(((apy / 100) * amount) / 12).toFixed(3)} {coinsAbv[coin]}
          </p>
        </div>
        <div className="flex flex-col items-center">
          <span className="text-sm">EST. YEARLY EARNING</span>
          <p className="font-bold text-2xl">
            {currencyFormatter.format(
              (apy / 100) * (prices[coin].usd * amount)
            )}
          </p>
          <p>
            {((apy / 100) * amount).toFixed(3)} {coinsAbv[coin]}
          </p>
        </div>
      </div>
      <p className="text-white text-xs mt-3">*Earnings can be increased depending on frequency of deposits</p>
    </div>
  );
}
