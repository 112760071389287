import React from "react";
import Grid from "@mui/material/Grid";
import BalanceCard from "../BalanceCard/BalanceCard";
import { useDashboardSection } from "../../context/dashSection";

export default function Balances({
  totalBalance,
  stakingBalance,
  investingBalance,
}) {
  const { setCurrentSection } = useDashboardSection();

  return (
    <Grid container spacing={3} className="my-8">
      <BalanceCard
        action="deposit"
        balance={totalBalance}
        onClick={() => setCurrentSection("receive")}
      />
      {stakingBalance && (
        <BalanceCard
          action="stake"
          title="Total Stakes"
          balance={stakingBalance}
          onClick={() => setCurrentSection("staking")}
        />
      )}
      {investingBalance && (
        <BalanceCard
          action="invest"
          title="Total Investments"
          balance={investingBalance}
          onClick={() => setCurrentSection("investments")}
        />
      )}
    </Grid>
  );
}
