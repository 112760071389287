import React, { useState, useRef } from "react";
import { NavLink } from "react-router-dom";

import Cookies from "js-cookie";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { CircularProgress, FormHelperText, Typography } from "@mui/material";
import { useAuth } from "../../context/auth";
import { Formik, Form, Field } from "formik";
import {
  validateConfirmPassword,
  validatePassword,
} from "../../utils/FormValidations";
import NotificationSystem from "react-notification-system";
import { changePassword } from "../../api/auth";
import LoadingScreen from "../LoadingScreen";
import KYC from "../KYC";

export default function Account() {
  const notificationSystem = useRef();
  const addNotification = ({ title, message, level }) => {
    const notification = notificationSystem.current;
    notification.addNotification({
      title,
      message,
      level,
    });
  };
  const [authError, setAuthError] = useState(null);
  const [showPassword, setShowPassword] = React.useState(false);
  const [buttonState, setButtonState] = useState(false);
  const { loading, user } = useAuth();
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const changePass = async (values) => {
    let currentPassword = values.currentPassword;
    let newPassword = values.newPassword;

    try {
      const token = Cookies.get("token");
      const response = await changePassword(
        token,
        JSON.stringify({
          current_password: currentPassword,
          new_password: newPassword,
        })
      );
      if (response.status === 200) {
        addNotification({
          title: "Success",
          message: "Password change successful",
          level: "success",
        });
      }
      setButtonState(false);
      setAuthError(null);
    } catch (err) {
      setButtonState(false);

      if (err.response.data.message) {
        setAuthError(err.response.data.message);
        addNotification({
          title: "Error",
          message: err.response.data.message,
          level: "error",
        });
      } else {
        setAuthError("An Unexpected Error has occured");
        addNotification({
          title: "Error",
          message: "An Unexpected Error has occured",
          level: "error",
        });
      }
      setAuthError(null);
    }
  };
  if (loading) return <LoadingScreen loading={loading} />;
  return (
    <>
      <NotificationSystem ref={notificationSystem} />
      <div className="flex flex-col max-w-sm  mb-8 border bg-black border-gray-500 rounded text-white">
        <h3 className="p-4 border-b border-gray-500 text-lg font-semibold">
          My Stakings
        </h3>
        <NavLink to="/staking" className="p-4 flex gap-3 hover:bg-gray-900">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 48 48"
            height="48"
            width="48"
            data-element="Learn"
          >
            <path
              fill="#BFE9FF"
              d="M30 30c8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15-8.284 0-15 6.716-15 15 0 8.284 6.716 15 15 15z"
            ></path>
            <path
              fill="#56B4FC"
              d="M3 24c0-8.475 7.05-15.3 15.525-15C26.7 9.3 33 16.2 33 24.375V48H15v-9h-4.5c-1.65 0-3-1.35-3-3v-6H3v-6z"
            ></path>
            <path
              fill="#1652F0"
              d="M18.525 9c-.825 0-1.575 0-2.325.15-.75 1.8-1.2 3.825-1.2 5.85 0 8.25 6.75 15 15 15 1.05 0 2.025-.075 3-.3v-5.325C33 16.2 26.7 9.3 18.525 9z"
            ></path>
            <path
              fill="#fff"
              d="m24 13.5 1.05 3.45L28.5 18l-3.45 1.05L24 22.5l-1.05-3.45L19.5 18l3.45-1.05L24 13.5z"
            ></path>
          </svg>
          <div>
            <h4>Learn about Staking</h4>
            <p className="text-gray-500">See how we calculate rewards</p>
          </div>
        </NavLink>
      </div>

      <Container
        maxWidth="lg"
        sx={{ mt: 4, mb: 20 }}
        className="flex flex-col px-0"
      >
        <div className="">
          <Typography variant="h5" className="text-white mt-8 mb-4">
            Referral
          </Typography>
          <FormControl sx={{ width: "25ch" }}>
            <InputLabel
              htmlFor="outlined-adornment-password"
              className="text-white"
            >
              {user.referral_code}
            </InputLabel>
            <OutlinedInput
              disabled
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              className="border border-white rounded-lg py-0"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() =>
                      navigator.clipboard.writeText(user.referral_code)
                    }
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    className="text-white"
                  >
                    <ContentCopyIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <Typography variant="h6" className="my-4 text-white text-base">
            Copy your referral code and share with your friends and earn up to
            30% bonuses
          </Typography>
        </div>
        <div>
          <Typography variant="h5" className="text-white mt-8 mb-4">
            KYC
          </Typography>
          <KYC />
        </div>
        <Typography variant="h5" className="text-white mb-4">
          Change Password
        </Typography>
        <Formik
          initialValues={{
            currentPassword: "",
            newPassword: "",
            newPassword2: "",
          }}
          onSubmit={(values) => {
            setButtonState(true);
            changePass(values);
          }}
        >
          {({
            errors,
            touched,
            handleChange,
            handleBlur,
            values,
            validateOnChange,
            /* and other goodies */
          }) => (
            <Form className="flex flex-col p-8 border border-slate-600 text-white rounded-sm w-full">
              <Field validate={validatePassword} name="currentPassword">
                {({
                  field, // { name, value, onChange, onBlur }
                  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta,
                }) => (
                  <FormControl variant="outlined" className="mb-6">
                    <InputLabel
                      htmlFor="outlined-adornment-password1"
                      className="text-white"
                    >
                      Current Password
                    </InputLabel>
                    <OutlinedInput
                      className="border text-white border-white w-full"
                      id="outlined-adornment-password1"
                      type={showPassword ? "text" : "password"}
                      {...field}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            className="text-white"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                    />
                    <FormHelperText className="text-red-200">
                      {errors.currentPassword &&
                        touched.currentPassword &&
                        errors.currentPassword}
                    </FormHelperText>
                  </FormControl>
                )}
              </Field>
              <Field validate={validatePassword} name="newPassword">
                {({
                  field, // { name, value, onChange, onBlur }
                  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta,
                }) => (
                  <FormControl variant="outlined" className="mb-6">
                    <InputLabel
                      htmlFor="outlined-adornment-password2"
                      className="text-white"
                    >
                      New Password
                    </InputLabel>
                    <OutlinedInput
                      className="border border-white w-full text-white"
                      id="outlined-adornment-password2"
                      type={showPassword ? "text" : "password"}
                      {...field}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            className="text-white"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                    />
                    <FormHelperText className="text-red-200">
                      {errors.newPassword &&
                        touched.newPassword &&
                        errors.newPassword}
                    </FormHelperText>
                  </FormControl>
                )}
              </Field>
              <Field
                validate={(value) =>
                  validateConfirmPassword(values.newPassword, value)
                }
                name="newPassword2"
              >
                {({
                  field, // { name, value, onChange, onBlur }
                  form: { touched, errors },
                  meta,
                }) => (
                  <FormControl variant="outlined" className="mb-6">
                    <InputLabel
                      htmlFor="outlined-adornment-password3"
                      className="text-white"
                    >
                      Repeat New Password
                    </InputLabel>
                    <OutlinedInput
                      className="border border-white w-full text-white"
                      id="outlined-adornment-password3"
                      type={showPassword ? "text" : "password"}
                      {...field}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            className="text-white"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                    />
                    <FormHelperText className="text-red-200">
                      {errors.newPassword2 &&
                        touched.newPassword2 &&
                        errors.newPassword2}
                    </FormHelperText>
                  </FormControl>
                )}
              </Field>

              <button
                type="submit"
                className="rounded-md mt-4 w-full pt-2.5 pr-3.5 pb-2.5 pl-3.5
                              text-white bg-blue-500 hover:bg-blue-700 cursor-pointer"
              >
                {buttonState ? (
                  <CircularProgress className="text-white" size={25} />
                ) : (
                  "Change Password"
                )}
              </button>
              <div className="text-red-400 mt-4">
                {authError ? <p>{authError} </p> : null}
              </div>
            </Form>
          )}
        </Formik>
      </Container>
    </>
  );
}
