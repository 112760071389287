// "use client";
import React from "react";
import ResponsiveDrawer from "../../components/Drawer";
import { useAuth } from "../../context/auth";
import LoadingScreen from "../../components/LoadingScreen";
import { Navigate } from "react-router-dom";

const Dashboard = () => {
  const { loading, isAuthenticated } = useAuth();
  if (loading) {
    return <LoadingScreen loading={loading} />;
  }
  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }
  return <ResponsiveDrawer></ResponsiveDrawer>;
};

export default Dashboard;
